import { FC, Fragment, useState } from 'react'
import { Box, createStyles, makeStyles, styled } from '@material-ui/core'
import { useRequestItemStepApprovers, useRequestStepApprovers } from 'hooks'
import { RequestedByNode } from './RequestedByNode'
import { StepNode } from './StepNode'
import { RequestItemNode } from './RequestItemNode'
import { Loader, StatusIndicator } from 'packages/eid-ui'
import ProgressIndicator from '../ProgressIndicator'
import { useTranslation } from 'react-i18next'
import { IRequestWithApprovalSteps } from 'types'
import { DiagramArrows } from './DiagramArrows'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            maxWidth: '1000px',
            backgroundColor: theme.palette.common.white,
            paddingLeft: '20px',
            paddingRight: '20px',
            minWidth: '1000px',
            position: 'relative',
        },
    }),
)

const ProcessSteps = ({ useDataHook, requestId, itemId, isCanceled }: any) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const {
        data: request,
        isFetching,
    }: { data: IRequestWithApprovalSteps; isFetching: boolean } = useDataHook(
        requestId,
        itemId,
    )

    const [showAllApprovers, setShowAllApprovers] = useState<{
        index: null | number
        stepNumber: null | string
    }>({
        index: null,
        stepNumber: null,
    })

    if (!request || isFetching) return <Loader />

    const progressIndicator = (
        <Fragment>
            <Box color="#5d6870" fontWeight="600" fontSize="12px">
                {t('MyTasks_PercentageCompleted', {
                    value: `${request.percentageCompleted} %`,
                })}
            </Box>
            <ProgressIndicator
                percentageCompleted={request?.percentageCompleted}
            />
        </Fragment>
    )

    return (
        <Box className={classes.root}>
            <Box position="absolute" right={45} top={32}>
                {isCanceled ? (
                    <StatusIndicator
                        variant={'Canceled'}
                        label={t(`MyTasks_Canceled`)}
                        size="large"
                    />
                ) : (
                    progressIndicator
                )}
            </Box>

            <RequestedByNode request={request} id={'requestedByNode'} />

            {request.globalApprovalSteps.map((s, index) => (
                <StepNode
                    key={`${s.stepNumber}_${index}`}
                    id={`global_${s.stepNumber}`}
                    requestId={requestId}
                    approverCount={request.approverCount}
                    useDataHook={useRequestStepApprovers}
                    showAllApprovers={
                        showAllApprovers.index === index &&
                        showAllApprovers.stepNumber === s.stepNumber
                    }
                    setShowAllApprovers={(show: any) => {
                        if (!show)
                            setShowAllApprovers({
                                index: null,
                                stepNumber: null,
                            })
                        else
                            setShowAllApprovers({
                                index,
                                stepNumber: s.stepNumber,
                            })
                    }}
                    step={s}
                />
            ))}
            <RequestItemProcessSteps request={request} />
            <DiagramArrows arrows={request.arrows} />
        </Box>
    )
}

const initialExpandState = {
    index: null,
    itemId: null,
    stepNumber: null,
}

const ShowMoreLink = styled(Box)({
    color: '#307fc1',
    fontSize: '10px',
    '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
})

export type RequestItemProcessStepsProps = {
    request: IRequestWithApprovalSteps
}

const RequestItemProcessSteps: FC<RequestItemProcessStepsProps> = (props) => {
    const { t } = useTranslation()

    const [showMax, setShowMax] = useState(50)

    const [showAllApprovers, setShowAllApprovers] = useState<{
        index: null | number
        itemId: null | string
        stepNumber: null | string
    }>(initialExpandState)

    return (
        <Fragment>
            {props.request.items.slice(0, showMax).map((i) => {
                return (
                    <Fragment key={i.id}>
                        <RequestItemNode id={`item_${i.id}`} item={i}>
                            {i.approvalSteps.map((s: any, index: any) => (
                                <StepNode
                                    key={`${i.id}_${s.stepNumber}_${index}`}
                                    id={`item_${i.id}_${s.stepNumber}`}
                                    requestId={props.request.id}
                                    itemId={i.id}
                                    approverCount={i.approverCount}
                                    useDataHook={useRequestItemStepApprovers}
                                    showAllApprovers={
                                        showAllApprovers.index === index &&
                                        showAllApprovers.itemId === i.id &&
                                        showAllApprovers.stepNumber ===
                                            s.stepNumber
                                    }
                                    setShowAllApprovers={(show: any) => {
                                        if (!show)
                                            setShowAllApprovers(
                                                initialExpandState,
                                            )
                                        else
                                            setShowAllApprovers({
                                                index: index,
                                                itemId: i.id,
                                                stepNumber: s.stepNumber,
                                            })
                                    }}
                                    step={s}
                                />
                            ))}
                        </RequestItemNode>
                        <DiagramArrows arrows={i.arrows} />
                    </Fragment>
                )
            })}
            {showMax < props.request.items.length && (
                <Box my={4} display="flex" justifyContent="center">
                    <ShowMoreLink
                        onClick={() => setShowMax((prev) => prev + 20)}
                    >
                        {t('MyTasks_ShowMore')}
                    </ShowMoreLink>
                </Box>
            )}
        </Fragment>
    )
}

export default ProcessSteps
