import React from 'react'
import { Grow, Menu, MenuItem, MenuList, styled } from '@material-ui/core'
import LanguageMenuItem from './LanguageMenuItem'
import { Backdrop } from '../Backdrop'

const StyledMenu = styled(
    ({ rootStyles, listStyles, menuHeight, width, ...rest }) => (
        <Menu {...rest} />
    ),
)(({ rootStyles, listStyles, menuHeight, width }) => ({
    '& .MuiMenu-paper': {
        borderRadius: '5px',
        width: width ? width : '240px',
        overflow: 'visible',
        paddingRight: '4px',
        ...rootStyles,

        '& .MuiMenu-list': {
            overflowX: 'visible',
            '& > ul': {
                maxHeight: menuHeight && menuHeight,
                overflow: 'auto',
                '&:focus': { outline: 'none' },
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    margin: '4px',
                    '-webkit-border-radius': '3px',
                    borderRadius: '3px',
                    backgroundColor: '#f7f7f7',
                },

                '&::-webkit-scrollbar-thumb': {
                    width: '3px',
                    borderRadius: '5px',
                    border: '2px solid transparent',
                    height: '20px',
                    backgroundColor: '#d8d8d8',
                },
            },
            ...listStyles,
        },
    },
}))

const ConeShapedDiv = styled('span')(({ position }) => ({
    width: 0,
    height: 0,
    borderLeft: '14px solid transparent',
    borderRight: '14px solid transparent',
    borderBottom: '14px solid #ffffff',
    position: 'absolute',
    right: '25px',
    top: '-13px',
    ...position,
}))

const StyledMenuItem = styled(({ style, ...rest }) => <MenuItem {...rest} />)(
    ({ style }) => ({
        minWidth: '160px',
        color: '#4a4a4a',
        fontSize: '14px',
        '&:hover, &:focus': {
            backgroundColor: '#e0ecf6 !important',
            color: '#000000',
        },
        '& svg': {
            marginRight: '10px',
        },
        ...style,
    }),
)

const Dropdown = (props) => {
    const {
        open,
        anchorEl,
        handleClose,
        handleListKeyDown,
        children,
        menuHeight,
        width,
        rootStyles,
        listStyles,
        backdrop,
        showCone,
        iconPosition,
        expandButton,
        ...rest
    } = props
    return (
        <StyledMenu
            rootStyles={rootStyles}
            listStyles={listStyles}
            menuHeight={menuHeight}
            width={width}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            TransitionComponent={Grow}
            onKeyDown={handleListKeyDown}
            disableScrollLock={true}
            BackdropComponent={backdrop && Backdrop}
            {...rest}
        >
            {showCone && <ConeShapedDiv position={iconPosition} />}

            <MenuList autoFocus={false} disabledItemsFocusable={false}>
                {children}
            </MenuList>
            {expandButton}
        </StyledMenu>
    )
}

Dropdown.Item = StyledMenuItem
Dropdown.LanguageMenuItem = LanguageMenuItem

Dropdown.defaultProps = {
    showCone: false,
}

export default Dropdown
