import { useEffect } from 'react'

const iframeStyles = {
    border: 'none',
    width: '100%',
    height: '600px',
    margin: 0,
    padding: 0,
    opacity: 0.8,
    display: 'block',
}

export type WorkflowIFrameProps = {
    baseEidUrl: string
    workflowName: string
    workflowParams?: string[]
    onComplete: () => void
}

export const WorkflowIFrame = (props: WorkflowIFrameProps) => {
    useEffect(() => {
        const eventListener = (e: WindowEventMap['message']) => {
            if (e.origin === props.baseEidUrl) {
                if (e.data.type === 'WorkflowEnded') {
                    setTimeout(props.onComplete, 3000)
                } else if (e.data.type === 'WorkflowCanceled') {
                    props.onComplete()
                }
            }
        }

        window.addEventListener('message', eventListener)

        return () => {
            window.removeEventListener('message', eventListener)
        }
    }, [])

    const fullWorkflowUrl = constructApprovalWorkflowUrl(
        props.baseEidUrl,
        props.workflowName,
        props.workflowParams,
    )

    return (
        <iframe
            title={`EmpowerID ${props.workflowName}`}
            style={iframeStyles}
            src={fullWorkflowUrl}
        />
    )
}

const constructApprovalWorkflowUrl = (
    baseEidUrl: string,
    workflowName: string,
    workflowParams?: string[],
) => {
    let queryParams = ['popupNav=true']

    if (workflowParams) {
        queryParams = queryParams.concat(workflowParams)
    }

    const queryString = queryParams.join('&')
    return `${baseEidUrl}/ui?hideHeader=true&wfMsContext=true&wfMsUrl=${window.location.origin}#w/${workflowName}?${queryString}`
}
