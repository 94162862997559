import { FC } from 'react'
import { Box } from '@material-ui/core'
import LoaderIcon from './Icons/LoaderIcon'
import AvatarGroup from 'components/AvatarGroup'
import { getStatusColor } from './utils'
import { RiskIcon } from './Icons/RiskIcon'
import TextFormatter from 'components/TextFormatter'
import { CanceledIcon } from './Icons'

const styles = {
    name: {
        fontSize: '14px',
        color: '#5d6870',
        fontWeight: 'bold',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    status: {
        color: '#9230c5',
        fontSize: '14px',
    },
    divider: {
        margin: '0px 10px',
        color: '#d8d8dd',
        width: '16px',
        height: '1px',
    },
}

const Approver: FC<any> = (props) => {
    const {
        id,
        size = 'large',
        currentStep,
        showAllApprovers,
        setShowAllApprovers,
        stepId,
        status,
        hasRisk,
    } = props

    const renderIcon = () => {
        if (hasRisk) {
            return <RiskIcon active={status === 'Open'} />
        } else if (status === 'Canceled') {
            return <CanceledIcon bgColor={'rgb(216, 216, 221)'} />
        } else {
            return <LoaderIcon active={status === 'Open'} />
        }
    }

    return (
        <Box position="relative">
            <Box marginY="7.5px" display="flex" alignItems="center">
                <Box id={id} minWidth={size} marginRight="10px">
                    {renderIcon()}
                </Box>
                <Box>
                    {status === 'Open' && stepId && (
                        <AvatarGroup
                            open={showAllApprovers}
                            setOpen={setShowAllApprovers}
                        />
                    )}
                    <TextFormatter
                        style={{
                            ...styles.status,
                            color: getStatusColor(status),
                        }}
                        value={currentStep}
                        maxCharacters={110}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Approver
