import { styled } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowSvg } from './arrow.svg'

const orientationAngle = {
    up: '0',
    right: '90',
    down: '180',
    left: '270',
}

const Arrow = styled(ArrowSvg)(({ theme, color, direction }) => ({
    transform: `rotate(${orientationAngle[direction]}deg)`,
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

Arrow.propTypes = {
    color: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
}

Arrow.defaultProps = {
    color: '#000000',
    direction: 'down',
}

export default Arrow
