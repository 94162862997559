import React from 'react'
import PropTypes from 'prop-types'
import { Box, styled } from '@material-ui/core'
import { Icon } from '../../eid-icons'

const styles = {
    small: {
        minHeight: '35px',
        lineHeight: '1 !important',
    },
    medium: {
        minWidth: '120px',
        height: '30px',
        padding: '5px',
        paddingLeft: '10px',
        lineHeight: '1 !important',
    },
    large: {
        minHeight: '36px',
        minWidth: '150px',
        padding: '6px',
        paddingLeft: '20px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '1 !important',
    },
}
const statuses = {
    Completed: {
        colors: {
            backgroundColor: '#e7f4ff',
            color: '#007fc7',
        },

        icon: <Icon name="CompletedStatus" />,
        gradient: 'linear-gradient(to right, #30acc1 0%, #3077c1 100%)',
    },

    Rejected: {
        colors: {
            backgroundColor: '#f9dada',
            color: '#d0021b',
        },
        icon: <Icon name="RejectedStatus" />,
        gradient: 'linear-gradient(to right, #d00245 0%, #d64d00 100%)',
    },
    Open: {
        colors: {
            backgroundColor: '#f9eada',
            color: '#e56300',
        },
        icon: <Icon name="OpenStatus" />,
        gradient: 'linear-gradient(to right, #eda300 0%, #d66a00 100%)',
    },
    Open_PendingApproval: {
        colors: {
            backgroundColor: '#f9eada',
            color: '#e56300',
        },
        icon: <Icon name="OpenStatus" />,
        gradient: 'linear-gradient(to right, #eda300 0%, #d66a00 100%)',
    },
    Open_PendingItemApproval: {
        colors: {
            backgroundColor: '#f9eada',
            color: '#e56300',
        },
        icon: <Icon name="OpenStatus" />,
        gradient: 'linear-gradient(to right, #eda300 0%, #d66a00 100%)',
    },
    NotStarted: {
        colors: {
            backgroundColor: '#aab0b4',
            color: '#5b6871',
        },
        icon: <Icon name="NotStartedStatus" />,
        gradient: 'linear-gradient(to right, #5d6870, #90a0ab)',
    },
    NotReady: {
        colors: {
            backgroundColor: '#aab0b4',
            color: '#5b6871',
        },
        icon: <Icon name="NotStartedStatus" />,
        gradient: 'linear-gradient(to right, #5d6870, #90a0ab)',
    },
    Error: {
        colors: {
            backgroundColor: '#d0021b',
            color: '#ffffff',
        },
        icon: <Icon name="ErrorStatus" color="#ffffff" />,
        gradient: 'linear-gradient(to right, #8a0111 0%, #af2000 100%)',
    },
    Expired: {
        colors: {
            backgroundColor: '#d0021b33',
            color: '#d0021b',
        },
        icon: <Icon name="ErrorStatus" color="#d0021b" />,
        gradient: 'linear-gradient(to right, #8a0111 0%, #af2000 100%)',
    },
    Approved: {
        colors: {
            backgroundColor: '#e1fee6',
            color: '#1eb77b',
        },
        icon: <Icon name="ApprovedStatus" color="#1EB758" />,
        gradient: 'linear-gradient(to right, #1eb758 0%, #1eb7a6 100%)',
    },
    Canceled: {
        colors: {
            backgroundColor: '#5d6870',
            color: '#ffffff',
        },
        icon: <Icon name="CanceledStatus" color="#ffffff" />,
    },
    Approve: {
        colors: {},
        gradient: ' linear-gradient(101deg, #01ae8f, #30c17c)',
    },
    Reject: {
        gradient: 'linear-gradient(102deg, #ae0142, #c14830 100%)',
    },
    Default: {
        colors: {},
        gradient: 'none',
    },
}

const MiniTag = styled('span')(({ image, width, style }) => ({
    display: 'table',
    padding: '0px 8px',
    borderRadius: '5px',
    backgroundColor: '#5d6870',
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#ffffff',
    backgroundImage: image,
    minWidth: width,
    ...style,
}))

const StyledDiv = styled('div')(({ colors, size }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors && colors.backgroundColor,
    color: colors && colors.color,
    border: ` solid 1px ${colors && colors.color}`,
    borderRadius: '6px',
    fontSize: '13px',
    overflow: 'hidden !important',
    overflowX: 'hidden',
    ...styles[size],
}))

const StatusIndicator = (props) => {
    const { size, variant, label, width, style } = props
    const variantToUse = variant === 'PreApproved' ? 'Approved' : variant
    const status = statuses[variantToUse]

    return size === 'small' ? (
        <MiniTag image={status?.gradient} width={width} style={style}>
            {label}
        </MiniTag>
    ) : (
        <StyledDiv colors={status?.colors} size={size}>
            <Box display="flex">{status?.icon}</Box>
            <div style={{ marginLeft: '10px' }}>{label}</div>
        </StyledDiv>
    )
}

StatusIndicator.propTypes = {
    variant: PropTypes.oneOf([
        'NotStarted',
        'NotReady',
        'Approved',
        'PreApproved',
        'Completed',
        'Open',
        'Open_PendingItemApproval',
        'Open_PendingApproval',
        'Rejected',
        'Error',
        'Reject',
        'Approve',
        'Default',
        'Expired',
        'Canceled',
    ]),
    size: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
}

StatusIndicator.defaultProps = {
    variant: 'Default',
}

export default StatusIndicator
