import React from 'react'
import {
    Tooltip as MuiTooltip,
    TooltipProps as MuiTooltipProps,
    makeStyles,
} from '@material-ui/core'

export interface StyleProps {
    fontColor?: string
    color?: string
    tooltipStyles: {}
}

const useStyles = makeStyles({
    tooltip: ({ color, fontColor, tooltipStyles }: StyleProps) => ({
        backgroundColor: color,
        color: fontColor,
        fontSize: '10px',
        borderRadius: '4px',
        ...tooltipStyles,
    }),
    arrow: {
        color: (props) => props.color,
        left: 'calc(50% - 5px) !important',
    },
})

export interface TooltipProps extends MuiTooltipProps {
    fontColor?: string
    color?: string
    arrow?: boolean
    tooltipStyles?: {}
}

const Tooltip: React.FC<TooltipProps> = ({
    fontColor = '#ffffff',
    color = '#000000',
    arrow = true,
    tooltipStyles = {},
    ...rest
}) => {
    const classes = useStyles({ color, fontColor, tooltipStyles })

    return <MuiTooltip arrow={arrow} classes={classes} {...rest} />
}

export default Tooltip
