import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import { isNilOrEmpty } from '../../core'
import { HorizontalTabs } from '../../eid-ui'
import { TagsIcon, FormsIcon } from '../../eid-icons'
import TagsFilter from './TagsFilter'

const getTabsCss = (colorCode) => {
    return {
        minWidth: '90px',
        color: '#777777',
        '& svg': {
            color: '#777777',
        },
        '&:hover': {
            color: colorCode.primary,
            '& svg': {
                color: colorCode.primary,
            },
        },
    }
}

const AdvancedSearchTabs = styled(({ colorCode, ...rest }) => (
    <HorizontalTabs {...rest} TabIndicatorProps={{ children: <span /> }} />
))(({ colorCode }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        '& > span': {
            maxWidth: 70,
            width: '100%',
            backgroundColor: colorCode.primary,
        },
    },
    '& .Mui-selected': {
        color: colorCode.primary,
        '& svg': {
            color: colorCode.primary,
        },
    },
}))

const FormsTab = styled(({ colorCode, ...rest }) => (
    <HorizontalTabs.Tab {...rest} />
))(({ colorCode }) => {
    return getTabsCss(colorCode)
})
const TagsTab = styled(({ colorCode, ...rest }) => (
    <HorizontalTabs.Tab {...rest} />
))(({ colorCode }) => {
    return getTabsCss(colorCode)
})

const AdvancedFilter = ({
    formsFilter,
    forms,
    tags,
    formsLabel,
    tagsLabel,
    loadTags,
    tabsContinerStyles,
    ...rest
}) => {
    useEffect(() => {
        if (loadTags) loadTags()
    }, [])

    const tabbed = !isNilOrEmpty(forms) && !isNilOrEmpty(tags)
    const [currentTab, setCurrentTab] = React.useState(0)

    return (
        <Box>
            {tabbed && (
                <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        style={{
                            paddingTop: '18px',
                            height: '96px',
                            backgroundColor: '#fbfbfd',
                            borderBottom: 'solid 1px #efeff1',
                            borderTop: 'solid 1px #efeff1',
                            ...tabsContinerStyles,
                        }}
                    >
                        <AdvancedSearchTabs
                            onChange={(_, index) => setCurrentTab(index)}
                            value={currentTab}
                            colorCode={
                                currentTab === 0
                                    ? forms.colorCode
                                    : tags.colorCode
                            }
                        >
                            <FormsTab
                                label={formsLabel}
                                icon={<FormsIcon />}
                                colorCode={forms.colorCode}
                            />
                            <TagsTab
                                label={tagsLabel}
                                icon={<TagsIcon />}
                                colorCode={tags.colorCode}
                            />
                        </AdvancedSearchTabs>
                    </Box>
                    <SwipeableViews
                        index={currentTab}
                        onChangeIndex={(index) => setCurrentTab(index)}
                    >
                        {formsFilter}
                        <TagsFilter {...tags} />
                    </SwipeableViews>
                </>
            )}
            {!tabbed && (
                <>
                    {!isNilOrEmpty(forms) && formsFilter}
                    {!isNilOrEmpty(tags) && <TagsFilter {...tags} />}
                </>
            )}
        </Box>
    )
}

AdvancedFilter.propTypes = {
    forms: PropTypes.shape({
        colorCode: PropTypes.object,
    }),
    tags: PropTypes.shape({
        colorCode: PropTypes.object,
        data: PropTypes.object,
        selected: PropTypes.array,
        onTagClick: PropTypes.func,
    }),
}

export default AdvancedFilter
