import { useRef } from 'react'
import { UserManager } from 'oidc-client'
import createUserManager from './createUserManager'
import { useAuthState } from './context'

const useUserManager = () => {
    const [{ settings }]: any = useAuthState()

    const userManagerRef = useRef<UserManager>()

    if (userManagerRef.current === undefined) {
        userManagerRef.current = createUserManager(settings)
    }

    return userManagerRef.current
}

export default useUserManager
