import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const TRANSLATIONS_KEY = 'TRANSLATIONS'
export const AVAILABLE_LANGUAGES_KEY = 'AVAILABLE_LANGUAGES'

export const useTranslations = () => {
    const callApi = useAxios()
    return useQuery(
        TRANSLATIONS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/localization?resourceSet=MyTasks',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useAvailableLocales = () => {
    const callApi = useAxios()
    return useQuery(
        AVAILABLE_LANGUAGES_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/Localization/languages',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
