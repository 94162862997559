import { useTranslation } from 'react-i18next'
import { Box, Link } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { StatusIndicator } from 'packages/eid-ui'
import { Button, TextFormatter } from 'components'
import { useAllApplications, useRequestItemSummaryEditFulfillmentDate } from 'hooks'
import { useUrlGenerator } from 'packages/core'
import moment from 'moment'
import { itShopSupportedResourceTypes } from 'components/SubjectTypeAttribute/supportedSubjectTypes'
import Attribute from './Attribute'
import useSubcomponents from 'useSubcomponents'
import { FulfillmentScheduleDateControl } from 'components/FulfillmentScheduleDateControl'

const styles = {
    name: {
        fontSize: '16px',
        color: '#307fc1',
        fontWeight: 'bold',
        maxWidth: '200px',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
        maxWidth: '200px',
    },
    approvalStep: {
        color: '#f94000',
        fontSize: '12px',

        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    emailPhone: {
        maxWidth: '200px',
        fontSize: '14px',
        color: '#307fc1',
    },
    borderBox: {
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSlice: 1,
        height: '1px',
        width: '292px',
        opacity: 0.25,
    },
    indicatorContainer: {
        position: 'absolute',
        top: '-13px',
        right: '8px',
    },
    indicator: {
        display: 'flex',
        padding: '4px 8px !important',
    },
    fulfillmentStatusIndicator: {
        display: 'flex',
        padding: '4px 8px !important',
        backgroundColor: '#8b909a',
        maxWidth: '195px',
    },
    fulfillmentStatusIndicatorContainer: {
        position: 'absolute',
        top: '-13px',
        left: '8px',
    },
    container: {
        width: '100%',
        padding: '16px 0px 75px 16px',
        borderRadius: '8px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        backgroundColor: '#ffffff',
        display: 'flex',
        position: 'relative',
        overflow: 'visible',
        height: '560px',
    },
    attributeContainer: {
        margin: '7.5px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
}

const ResourceTypeCard = ({
    businessRequest,
    item,
    itemTypeId,
    approversExpanded,
    onApproversExpansion,
    commentsExpanded,
    onCommentsExpansion,
    violationsExpanded,
    onViolationsExpansion,
}) => {
    const { t } = useTranslation()

    const history = useHistory()

    const area = history.location.pathname.split('/')[1]

    const { getItShopUrl } = useUrlGenerator(useAllApplications)

    const itemStatus = item.preApproved ? 'PreApproved' : item.status

    const getITShopSubjectTypePath = (item) => {
        if (!itShopSupportedResourceTypes.includes(item.subjectType))
            return undefined
        if (item.subjectType === 'AzureRole') {
            switch (item.otherProperties.AzureRoleType) {
                case 'Admin':
                    return 'AzureAdminRole'
                case 'Rbac':
                    return 'AzureRbacRole'
                default:
                    return undefined
            }
        } else return item.subjectType
    }

    const showCurrentApprovalStepName = item.currentApprovalStepName

    const { canSeeRiskViolationsByRisks } = useSubcomponents()

    const [editFulfillmentScheduleDate] =
        useRequestItemSummaryEditFulfillmentDate(item.id, item.businessRequestId, itemTypeId)

    return (
        <>
            <Box style={styles.container}>
                <Box style={styles.indicatorContainer}>
                    <StatusIndicator
                        variant={itemStatus}
                        label={t(`MyTasks_${itemStatus}`)}
                        size="small"
                        style={styles.indicator}
                    />
                </Box>

                <Box
                    overflow="auto"
                    maxHeight={showCurrentApprovalStepName ? '436px' : '643px'}
                    width="100%"
                >
                    {(itemStatus === 'Approved' ||
                        itemStatus === 'PreApproved') &&
                        item.fulfillmentStatusFriendlyName && (
                            <Box
                                style={
                                    styles.fulfillmentStatusIndicatorContainer
                                }
                            >
                                <StatusIndicator
                                    label={`${t('MyTasks_Fulfillment')} ${
                                        item.fulfillmentStatusFriendlyName
                                    }`}
                                    size="small"
                                    style={styles.fulfillmentStatusIndicator}
                                />
                            </Box>
                        )}

                    <Box position="relative">
                        {item.fulFillmentScheduleDateUtc && (
                            <FulfillmentScheduleDateControl item={item} onEdit={editFulfillmentScheduleDate} />
                        )}

                        <Box marginY="7.5px" display="flex" alignItems="center">
                            <Attribute
                                style={styles.name}
                                value={item.itemTypeActionFriendlyName}
                                label={t('MyTasks_ActionType')}
                                orientation="vertical"
                            />
                        </Box>

                        {item.additionalProperties &&
                            Object.keys(item.additionalProperties).map((k) => (
                                <Box key={k} style={styles.attributeContainer}>
                                    <Attribute
                                        style={styles.name}
                                        value={item.additionalProperties[
                                            k
                                        ]?.toString()}
                                        label={t(`MyTasks_${k}`)}
                                        orientation="vertical"
                                    />
                                </Box>
                            ))}

                        {item.startDateUtc && (
                            <Box style={styles.attributeContainer}>
                                <Attribute
                                    value={moment
                                        .utc(item.startDateUtc)
                                        .local()
                                        .format('LLL')}
                                    label={t('MyTasks_AccessStartDate')}
                                    orientation="vertical"
                                />
                            </Box>
                        )}

                        {item.endDateUtc && (
                            <Box style={styles.attributeContainer}>
                                <Attribute
                                    value={moment
                                        .utc(item.endDateUtc)
                                        .local()
                                        .format('LLL')}
                                    label={t('MyTasks_AccessEndDate')}
                                    orientation="vertical"
                                />
                            </Box>
                        )}

                        <Box
                            height={
                                approversExpanded || commentsExpanded
                                    ? '20px'
                                    : '0px'
                            }
                            width="100%"
                        />
                    </Box>

                    <Box
                        height={showCurrentApprovalStepName ? '102px' : '68px'}
                        paddingRight={'16px'}
                        position={'absolute'}
                        bottom={0}
                        left={0}
                        overflow={'hidden'}
                        display={'flex'}
                        flexDirection={'column'}
                        width={'100%'}
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'flex-end'}
                            marginRight={'8px'}
                            marginLeft={'16px'}
                            flexDirection={'column'}
                        >
                            {showCurrentApprovalStepName && (
                                <Box
                                    width={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    textAlign={'right'}
                                    overflow={'hidden'}
                                    maxHeight={'42px'}
                                    marginBottom={'12px'}
                                >
                                    <TextFormatter
                                        style={styles.approvalStep}
                                        value={item.currentApprovalStepName}
                                        maxCharacters={42}
                                        showTooltip={'always'}
                                    />
                                </Box>
                            )}
                            <Box display={'flex'} justifyContent={'flex-end'}>
                                {getItShopUrl() &&
                                    item.subjectId &&
                                    getITShopSubjectTypePath(item) && (
                                        <Link
                                            href={getItShopUrl(
                                                `/${getITShopSubjectTypePath(
                                                    item,
                                                )}s?selected=${item.subjectId}`,
                                            )}
                                            target="_blank"
                                            style={{ marginRight: '10px' }}
                                        >
                                            {t('MyTasks_ViewResource')}
                                        </Link>
                                    )}
                                <Link
                                    href={`/${area}/businessRequestItems?id=${item.id}`}
                                    target="_blank"
                                >
                                    {t('MyTasks_ViewItem')}
                                </Link>
                            </Box>
                        </Box>
                        <Box display="flex">
                            {businessRequest.status !==
                                'Open_PendingApproval' &&
                                itemStatus !== 'Approved' &&
                                itemStatus !== 'Rejected' &&
                                itemStatus !== 'PreApproved' && (
                                    <Button.Expand
                                        type="Approvers"
                                        handleExpand={onApproversExpansion}
                                        expanded={approversExpanded}
                                    />
                                )}
                            <Button.Expand
                                type="Comments"
                                handleExpand={onCommentsExpansion}
                                expanded={commentsExpanded}
                            />
                            {item.riskCount > 0 &&
                                canSeeRiskViolationsByRisks && (
                                    <Button.Expand
                                        type={'Violations'}
                                        handleExpand={onViolationsExpansion}
                                        expanded={violationsExpanded}
                                    />
                                )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!approversExpanded && !commentsExpanded && (
                <Box width="301px" display="flex" alignItems="center">
                    <Box style={styles.borderBox} />
                </Box>
            )}
        </>
    )
}
export default ResourceTypeCard
