import React from 'react'
import { Card, makeStyles } from '@material-ui/core/'

const useCardStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
        border: 'none',
    },
}))

const CustomCard = ({ children }) => {
    const cardClasses = useCardStyles()

    return <Card classes={cardClasses}>{children}</Card>
}

export default CustomCard
