import { Box, makeStyles, styled, Typography } from '@material-ui/core'
import { isNilOrEmpty } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'
import React from 'react'
import { smallScreenWidth } from 'utils'

const tooltipDelay = 1000

export const useStyles = makeStyles({
    profileContainer: {
        width: '100%',
        paddingRight: '0px',

        [`@media (min-width:${smallScreenWidth})`]: {
            width: '40%',
            paddingRight: '36px',
        },
    },
    detailsContainer: {
        paddingLeft: '36px',
        width: '60%',
        [`@media (max-width:${smallScreenWidth})`]: {
            width: '100%',
            paddingLeft: '0px',
        },
    },
    progressAttribute: {
        display: 'flex',
        alignItems: 'center',
        [`@media (max-width:${smallScreenWidth})`]: {
            alignItems: 'flex-start',
        },
    },

    progressIndicatorContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& >div:first-child': {
            paddingRight: '10px',
        },
        [`@media (max-width:${smallScreenWidth})`]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& >div:first-child': {
                paddingBottom: '20px',
            },
        },
    },

    person: {
        width: 'calc(100% - 150px)',
        maxWidth: '375px',
    },
})

export const AttributeLabel = styled(Box)({
    minWidth: '120px',
    maxWidth: '120px',
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#b4b4b4',
    marginLeft: '13px',
    marginRight: '13px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    overflow: 'auto',
})

export const StyledTypography = styled(Typography)({
    fontSize: '14px',
    color: '#000000',
})

export const AttributeValue = ({ value, ...rest }) =>
    isNilOrEmpty(value) ? (
        <span style={{ color: '#9b9b9b' }}>-</span>
    ) : (
        <Tooltip
            title={value ? value : ''}
            placement="bottom-start"
            enterDelay={tooltipDelay}
            enterNextDelay={tooltipDelay}
        >
            <StyledTypography {...rest}>{value}</StyledTypography>
        </Tooltip>
    )

export const styles = {
    attributeValue: {
        fontSize: '14px',
        color: '#000000',
    },
    roleReceiverValue: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#282828',
    },
    locationValue: {
        color: '#307fc1',
    },
    breakWord: {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        overflow: 'auto',
    },
    divider: {
        marginBottom: 30,
    },
}

export const VerticalAlignedLabel = styled(AttributeLabel)({
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    overflow: 'auto',
})

export const Container = styled(Box)({
    bgcolor: '#ffffff',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '30px',
    paddingBottom: '12px',
    display: 'flex',
    width: '100%',
    overflow: 'hidden',

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column',
        padding: '20px 12px',
    },
})

export const AttributeContainer = styled(Box)({
    display: 'flex',
    margin: '10px 0px',
})

export const getLocationString = (city, country) => {
    let location = ''
    if (city) {
        location += city
    }
    if (country) {
        location = location ? `${location}, ${country}` : country
    }
    return location
}
