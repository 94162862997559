import React from 'react'
import PropTypes from 'prop-types'
import { Pagination as MuiPagination } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core'
import DefaultItem from './PaginationItem'

const defaultListStyles = {
    borderRadius: '5px',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    '& >li:first-child': {
        '& > button': {
            borderRadius: '5px 0px 0px 5px !important',
        },
    },
    '& >li:last-child': {
        '& > button': {
            borderRadius: '0px 5px 5px 0px !important',
            borderRight: '1px solid #ebebed',
        },
    },
}

const useStyles = makeStyles({
    ul: (props) => ({
        ...props.listStyles,
    }),
})

const Pagination = (props) => {
    const {
        size,
        listStyles,
        PaginationItemComponent: PaginationItem,
        ...rest
    } = props
    const classes = useStyles({ listStyles })

    return (
        <MuiPagination
            classes={classes}
            renderItem={(item) => <PaginationItem size={size} {...item} />}
            size={size}
            {...rest}
        />
    )
}

Pagination.defaultProps = {
    listStyles: defaultListStyles,
    PaginationItemComponent: DefaultItem,
}

export default Pagination
