export const initialState = {
    searchTerm: '',
    page: 1,
    startDate: null,
    endDate: null,
    sortOrder: null,
    sortBy: null,
    subjectType: null,
    subject: null,
    requestedBy: null,
    approver: null,
    approverType: 'current',
    showOnlyWhereNoApprovers: null,
    showOnlyPreApproved: false,
    status: [],
    fulfillmentStatus: null,
    itemType: [],
    riskLevels: [],
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PROP':
            return {
                ...state,
                page: 1,
                [payload.key]: payload.value,
            }
        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                page: 1,
                ...payload,
            }

        case 'SET_PAGE':
            return {
                ...state,
                page: payload,
            }

        default:
            return state
    }
}

export default reducer
