import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { isEmptyOrWhitespace } from 'packages/core'
import { StyledInput } from './utils'
import { COMMENT_MAX_LENGTH } from 'utils'
import { IconButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'

const EditComment = ({
    id,
    initialValue = '',
    handleEditComment,
    loading,
    setEditComment,
    variant = 'light',
}) => {
    const [inputText, setInputText] = useState(initialValue)

    const notSubmitable =
        inputText.length > COMMENT_MAX_LENGTH ||
        isEmptyOrWhitespace(inputText) ||
        inputText === initialValue ||
        loading

    const handleChange = (event) => {
        const keyword = event.target.value
        setInputText(keyword)
    }

    const handleKeyDown = (event) => {
        if (event.which === 13 && !notSubmitable) {
            handleEditComment(id, inputText)
            setEditComment(false)
            setInputText('')
            event.preventDefault()
        }
    }

    return (
        <Box
            width="100%"
            marginRight="15px"
            position="relative"
            paddingTop="4px"
            paddingBottom="36px"
            minHeight="80px"
        >
            <StyledInput
                fullWidth
                multiline
                autoFocus
                value={inputText}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                error={
                    inputText.length > COMMENT_MAX_LENGTH ||
                    isEmptyOrWhitespace(inputText)
                }
                onBlur={() => setEditComment(false)}
            />
            <Box
                position="absolute"
                right={5}
                display="flex"
                paddingY="10px"
                alignItems="center"
            >
                <SuccessButton
                    onClick={() => {
                        handleEditComment(id, inputText)
                        setEditComment(false)
                    }}
                    disabled={notSubmitable}
                    color={colors[variant].icon}
                />

                <CloseButton
                    onClick={() => setEditComment(false)}
                    color={colors[variant].icon}
                />

                <Box
                    fontSize="10px"
                    paddingLeft="16px"
                    color={colors[variant]?.text}
                >
                    {inputText.length}/{COMMENT_MAX_LENGTH}
                </Box>
            </Box>
        </Box>
    )
}

export default EditComment

const colors = {
    light: {
        text: '#ffffff',
        icon: '#ffffff',
    },
    dark: {
        text: '#b4b4b4',
        icon: '#307fc1',
    },
}

const CloseButton = ({ onClick, color, ...rest }) => {
    return (
        <Box marginLeft="10px">
            <IconButton onClick={onClick} size="small" {...rest}>
                <Icon name="Close" height="13px " width="13px" color={color} />
            </IconButton>
        </Box>
    )
}

const SuccessButton = ({ onClick, color, disabled }) => {
    const iconColor = disabled ? '#b4b4b4' : color

    return (
        <Box marginLeft="10px">
            <IconButton onClick={onClick} size="small" disabled={disabled}>
                <Icon
                    name="Approve"
                    height="14px "
                    width="14px"
                    color={iconColor}
                />
            </IconButton>
        </Box>
    )
}
