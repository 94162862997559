import React from 'react'
import { Box, Link, Typography, styled } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'

const Container = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    width: 'auto',
    paddingLeft: '15px',
    paddingBottom: '20px',
}))

const ContentFooter = ({ title, data,settings, colorCodes }) => {
    return (
        <Container>
            {title && (
                <Box paddingBottom="20px">
                    <Typography
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                    >
                        {title}
                    </Typography>
                </Box>
            )}

            {data.map((i, index) => (
                <Link
                    key={index}
                    href={i.path}
                    underline="none"
                    //component="button"
                    style={{
                        color: colorCodes.secondary,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            marginRight: '16px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Icon name={i.icon} color={colorCodes.primary} />
                    </div>
                    <Typography style={{ fontSize: '12px' }}>
                        {settings.signOut.label}
                    </Typography>
                </Link>
            ))}

            {/* <Divider style={{ margin: '15px 0px' }} /> */}
        </Container>
    )
}

export default ContentFooter
