import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
    defaultNS: 'translation',
    lng: 'en',
    debug: process.env.NODE_ENV === 'development',
    keySeparator: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
        useSuspense: false,
    },
})

export default i18n
