import { FC, memo } from 'react'
import {
    Box,
    Collapse,
    createStyles,
    makeStyles,
    styled,
    Typography,
} from '@material-ui/core'
import { getStatusColor } from './utils'
import Icon from './Icons/Icon'
import { Icon as EidIcon } from 'packages/eid-icons'
import ArrowIcon from './Icons/ArrowIcon'
import Approver from './Approver'
import moment from 'moment'
import { Status } from './Status'
import { StepApproversList } from './StepApproversList'
import { Avatar } from 'packages/eid-ui'
import { IApprovalStep } from 'types'
import { getFullImageUrl } from 'utils'
import { RiskViolationLeftRight } from '../V2/RiskViolationLeftRight'
import classNames from 'classnames'
import TextFormatter from 'components/TextFormatter'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginTop: '12px',
            marginBottom: '12px',
            paddingTop: '8px',
            paddingBottom: '8px',
            position: 'relative',
            '& *': {
                zIndex: 2,
            },
        },
        rootExpanded: {
            '& *': {
                zIndex: 3,
            },
        },
        nonRiskApproverNode: {
            height: '84px',
        },
        riskApproverNode: {
            height: '192px',
        },
        container: {
            position: 'relative',
            border: 'solid 1px transparent ',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        openStep: {
            backgroundColor: '#f9eada',
            border: 'solid 1px #d76e00',
        },
    }),
)

const styles = {
    name: {
        fontSize: '14px',
        color: '#5d6870',
        fontWeight: 'bold',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    status: {
        color: '#01ae8f',
        fontSize: '14px',
    },
    divider: {
        margin: '0px 10px',
        color: '#d8d8dd',
        width: '16px',
        height: '1px',
    },
}

export type StepNodeProps = {
    id?: string
    requestId: string
    itemId?: string
    approverCount: null | number
    useDataHook: any
    showAllApprovers: boolean
    setShowAllApprovers: any
    step: IApprovalStep
}

const leftMarginsMap = {
    0: '0',
    1: '56px',
    2: '112px',
}

const getViolation = (risk: any) => {
    const violation: any = {
        extendedRiskDescription: risk.riskDescription,
        riskType: risk.riskTypeID,

        riskDetails: {
            riskReason: risk.riskFunctionSource,
            requestId: risk.riskBusinessRequestID,
            requestNumber: risk.riskRequestNumber,
            riskFunctionAssignee: risk.riskFunctionAssignee,
        },
    }
    if (violation.riskType === 'SegregationOfDuties') {
        violation.segregatedRiskDetails = {
            riskReason: risk.riskSegregatedFunctionSource,
            requestId: risk.segregatedRiskBusinessRequestID,
            requestNumber: risk.segregatedRiskRequestNumber,
            riskFunctionAssignee: risk.riskSegregatedFunctionAssignee,
        }
    }
    return violation
}

const StepNode: FC<StepNodeProps> = memo((props) => {
    const classes = useStyles()

    return (
        <Box
            className={classNames(classes.root, {
                [classes.rootExpanded]: props.showAllApprovers,
                [classes.nonRiskApproverNode]:
                    !props.step.approver && !props.step.risk,
                [classes.riskApproverNode]:
                    !props.step.approver &&
                    props.step.risk &&
                    props.step.status === 'Open',
            })}
            marginLeft={leftMarginsMap[props.step.indentationLevel]}
        >
            <Box
                borderRadius="6px"
                className={classNames(classes.container, {
                    [classes.openStep]: props.step.status === 'Open',
                })}
            >
                <Box display="flex" alignItems="center" position="relative">
                    <Box width="100%">
                        {props.step.approver ? (
                            <Box position="relative" width="100%">
                                <Box
                                    marginY="7.5px"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Box minWidth={'large'} marginRight="10px">
                                        <Avatar
                                            id={props.id}
                                            size={'large'}
                                            src={getFullImageUrl(
                                                props.step.approver
                                                    .imageThumbUrl,
                                            )}
                                        />
                                    </Box>
                                    <Box>
                                        {/*@ts-ignore*/}
                                        <Typography style={styles.name}>
                                            {props.step.approver.friendlyName}
                                        </Typography>

                                        <Box display="flex" alignItems="center">
                                            <TextFormatter
                                                style={{
                                                    ...styles.status,
                                                    color: getStatusColor(
                                                        props.step.status,
                                                    ),
                                                }}
                                                value={props.step.friendlyName}
                                                maxCharacters={100}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Approver
                                id={props.id}
                                stepId={props.step.stepId}
                                status={props.step.status}
                                hasRisk={Boolean(props.step.risk)}
                                currentStep={props.step.friendlyName}
                                showAllApprovers={props.showAllApprovers}
                                setShowAllApprovers={() =>
                                    props.setShowAllApprovers(
                                        !props.showAllApprovers,
                                    )
                                }
                            />
                        )}
                    </Box>

                    <ArrowIcon
                        variant={props.step.status}
                        style={{ margin: '0px 20px' }}
                    />

                    <Box position="relative">
                        <Icon
                            icon={props.step.status}
                            label={
                                <Status
                                    status={props.step.status}
                                    autoApprove={props.step.autoApprove}
                                />
                            }
                        />

                        {props.step.approvedOnUtc && (
                            <DateTimeContainer>
                                <EidIcon
                                    height="16px"
                                    width="16px"
                                    name="Clock"
                                    color="#aab0b4"
                                />

                                <DateTime noWrap>
                                    {moment
                                        .utc(props.step.approvedOnUtc)
                                        .local()
                                        .format('LLL')}
                                </DateTime>
                            </DateTimeContainer>
                        )}
                    </Box>
                </Box>
                <Collapse in={props.showAllApprovers}>
                    <AllApproversContainer>
                        {props.showAllApprovers && props.step.stepId && (
                            <StepApproversList
                                totalApproversCount={props.approverCount}
                                requestId={props.requestId}
                                itemId={props.itemId}
                                stepId={props.step.stepId}
                                useDataHook={props.useDataHook}
                            />
                        )}
                    </AllApproversContainer>
                </Collapse>
                {props.step.risk && (
                    <Box marginY={'7px'}>
                        <RiskViolationLeftRight
                            showFunctions={false}
                            requestId={props.requestId}
                            itemId={props.itemId}
                            page={'Requests'}
                            violation={getViolation(props.step.risk)}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    )
})

const AllApproversContainer = styled(Box)({
    backgroundColor: '#f5f5f6',

    padding: '4px',

    maxHeight: '184px',

    overflowY: 'auto',

    borderRadius: '8px',
    border: 'solid 1px #d8d8dd',
    boxShadow: ' 0 6px 16px 0 rgba(0, 0, 0, 0.15)',
    width: '100%',
    margin: '20px 0px 16px 0px',
})

const DateTimeContainer = styled(Box)({
    position: 'absolute',
    left: 0,
    bottom: '-32px',

    height: '32px',

    display: 'flex',
    alignItems: 'center',
})

const DateTime = styled(Typography)({
    paddingLeft: '5px',
    color: '#aab0b4',
    fontSize: '12px',
})

export { StepNode }
