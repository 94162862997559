const statusColors = {
    Approved: '#01ae8f',
    Rejected: '#d0021b',
    DoNothing: '',
    Open: '#d66a00',
    NotStarted: '#d8d8dd',
    NotReady: '#d8d8dd',
    Canceled: '#5d6870'
}

export const getStatusColor = (status, defaultColor = '#d66a00') => {
    return Object.keys(statusColors).includes(status)
        ? statusColors[status]
        : defaultColor
}

export { default as ArrowIcon } from './ArrowIcon'
export { default as ApprovedIcon } from './ApprovedIcon'
export { default as OpenIcon } from './OpenIcon'
export { default as NotStartedIcon } from './NotStartedIcon'
export { default as NotReadyIcon } from './NotReadyIcon'
export { default as LoaderIcon } from './LoaderIcon'
export { default as RejectedIcon } from './RejectedIcon'
export { default as CanceledIcon } from './CanceledIcon'
