import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { FilterChip } from '../../eid-ui'

const Tag = ({ label, selected, colorCode }) => {
    const color = colorCode?.primary
    return (
        <FilterChip
            label={label}
            style={{
                height: '25px',
                maxWidth: '330px',
                borderRadius: '16.5px',
                color: selected ? color : '#91959d',
                border: selected ? `solid 1px ${color}` : 'solid 1px #91959d ',
                padding: '10px 20px ',
                cursor: 'pointer',
                '&:hover': {
                    border: `1px solid ${color}`,
                    color: color,
                },
            }}
        />
    )
}

const TagsFilter = ({ colorCode, allTags, selectedTags, onTagClick }) => {
    return (
        <Box padding="20px 20px 0px 20px" display="flex" flexWrap="wrap">
            {Object.keys(allTags).length === 0 && <>No tags available</>}
            {Object.keys(allTags).map((tag, index) => (
                <Box
                    padding="2.5px"
                    key={index}
                    onClick={() => onTagClick(tag)}
                >
                    <Tag
                        label={`${tag} (${allTags[tag]})`}
                        selected={
                            selectedTags.findIndex((f) => f === tag) !== -1
                                ? true
                                : false
                        }
                        colorCode={colorCode}
                    />
                </Box>
            ))}
        </Box>
    )
}

TagsFilter.propTypes = {
    tags: PropTypes.object,
    selectedTags: PropTypes.array,
    onTagClick: PropTypes.func,
}

export default TagsFilter
