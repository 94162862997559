import React from 'react'
import {
    withStyles,
    Typography,
    Accordion as MuiExpansionPanel,
    AccordionSummary as MuiExpansionPanelSummary,
    AccordionDetails as MuiExpansionPanelDetails,
    Collapse,
} from '@material-ui/core'
import { ArrowIcon } from 'packages/eid-icons'

const StyledPanel = withStyles({
    root: {
        width: '100%',
        border: 'none !important',
        //boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: '0px !important',
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {
        margin: '0 !important',
    },
})(MuiExpansionPanel)

const StyledPanelSummary = withStyles({
    root: {
        minHeight: '0 !important',
        margin: '0 !important',
        borderBottom: '1px solid #e8e8ec !important',
    },
    expanded: {
        minHeight: '0 !important',
        marginTop: '0 !important',
        marginBottom: '0 !important',
        '& p': { fontWeight: 'bold !important' },
    },
    content: {},
})(MuiExpansionPanelSummary)

const StyledPanelDetails = withStyles({
    root: {
        display: 'block',
        padding: 0,
        //padding: '16px 0 16px 0',
        backgroundColor: '#fbfbfd',
    },
})(MuiExpansionPanelDetails)

const ExpansionPanel = ({ expanded, children, title, ...rest }) => {
    const [_expanded, setExpanded] = React.useState(expanded)

    return (
        <StyledPanel
            expanded={_expanded}
            onChange={() => setExpanded(!_expanded)}
            {...rest}
        >
            <StyledPanelSummary
                expandIcon={<ArrowIcon direction="down" color="#919193" />}
            >
                <Typography>{title}</Typography>
            </StyledPanelSummary>
            <Collapse in={_expanded} timeout={400} unmountOnExit>
                <StyledPanelDetails>{children}</StyledPanelDetails>
            </Collapse>
        </StyledPanel>
    )
}

export default ExpansionPanel
