import { queryCache } from 'react-query'
import { REQUESTS_KEY } from './requestHooks'
import { REQUEST_ITEMS_KEY } from './requestItemHooks'
import * as R from 'ramda'
import {
    IApprovalStep,
    IItemWithApprovalSteps,
    IItemWithRisks,
    IndentationLevel,
    IRequestWithApprovalSteps,
    RiskReason,
} from 'types'
import { roundOffNumber } from 'utils'
import { getStatusColor } from '../components/ProcessSteps/utils'

export const refreshRequest = (requestId: string) => {
    queryCache.invalidateQueries(
        (q) =>
            q.queryKey.includes(REQUESTS_KEY) && q.queryKey.includes(requestId),
    )
}

export const refreshRequestItemsByItemType = (
    requestId: string,
    itemTypeId: string,
) => {
    queryCache.invalidateQueries(
        (q) =>
            q.queryKey.includes(REQUESTS_KEY) &&
            q.queryKey.includes(requestId) &&
            q.queryKey.includes(itemTypeId) &&
            q.queryKey.includes('REQUEST_ITEMS_BY_ITEM_TYPE'),
    )
}
export const refreshRequestItem = (requestItemId: string) => {
    queryCache.invalidateQueries(
        (q) =>
            q.queryKey.includes(REQUEST_ITEMS_KEY) &&
            q.queryKey.includes(requestItemId),
    )
}

export const refreshRequestOrRequestItem = (
    page: string,
    requestId: string,
    requestItemId?: string,
) => {
    if (page === 'Requests') {
        refreshRequest(requestId)
    } else {
        refreshRequestItem(requestItemId as string)
    }
}

export const refreshComments = (
    page: string,
    requestId: string,
    requestItemId?: string,
) => {
    if (page === 'Requests') {
        queryCache.invalidateQueries(
            (q) =>
                q.queryKey.includes(REQUESTS_KEY) &&
                q.queryKey.includes(requestId) &&
                (q.queryKey.includes('REQUEST_COMMENTS') ||
                    q.queryKey.includes('REQUEST_View_ITEM_COMMENTS')),
        )
    } else {
        queryCache.invalidateQueries(
            (q) =>
                q.queryKey.includes(REQUEST_ITEMS_KEY) &&
                q.queryKey.includes(requestItemId) &&
                q.queryKey.includes('REQUEST_ITEM_COMMENTS'),
        )
    }
}

const toItemWithRisks: (requestId: string, input: any) => IItemWithRisks = (
    requestId,
    item,
) => ({
    requestId: requestId,
    id: item.id,
    inverted: item.riskDetailsInversed,
    left: item.riskDetails,
    right: item.segregatedRiskDetails,
    risks: [item],
})

export const groupByItems: (
    requestId: string,
    input: any,
) => IItemWithRisks[] = (requestId, input) => {
    return R.pipe(
        // @ts-ignore
        R.groupBy((x: any) => {
            if (x.riskDetailsInversed) return x.segregatedRiskDetails.itemId
            else return x.itemId
        }),
        R.map((x) => {
            return x.reduce((items: any, item: any) => {
                if (items === undefined) {
                    return { ...toItemWithRisks(requestId, item) }
                } else {
                    items.risks.push(item)
                    return items
                }
            }, undefined)
        }),
        R.values,
        R.flatten,
    )(input) as IItemWithRisks[]
}

export const groupByRisks: (input: any) => any = (input) => {
    const response = R.pipe(
        //@ts-ignore
        R.groupBy((x) => x.localRiskId),
        R.map((x: any) => {
            return x.reduce((obj: any, current: any) => {
                if (obj === undefined) {
                    return {
                        ...current,
                        violations: [current],
                    }
                } else {
                    obj.violations.push(current)
                    return obj
                }
            }, undefined)
        }),
        R.values,
        R.flatten,
    )(input)

    return response
}

export const augmentRisksWithDecisions: any = (items: any, risks: any) => {
    const risksGrouped: any = R.mergeAll(
        risks.map((r: any) => ({
            [r.id]: r,
        })),
    )

    const risksWithDecisions = []
    for (let i = 0; i < items.length; i++) {
        const currentItem = items[i]

        if (!currentItem.riskId) continue

        const relevantRisk = risksGrouped[currentItem.riskId]

        if (!relevantRisk || relevantRisk.mitigationStatus !== 'NotMitigated')
            continue

        risksWithDecisions.push({
            ...relevantRisk,
            itemId: currentItem.id,
            currentApprovalStepId: currentItem.currentApprovalStepId,
            decisions: currentItem.decisions,
        })
    }

    return risksWithDecisions
}

const convertToSource: (input: string) => RiskReason = (riskFunctionSource) => {
    switch (riskFunctionSource) {
        case 'THIS REQUEST':
        case 'BUSINESS REQUEST':
            return 'BusinessRequest'
        case 'MEMBERSHIP':
            return 'Membership'
        default:
            return 'Unknown'
    }
}

const transformStep: (
    item: any,
    data: any,
    indentationLevel: IndentationLevel,
) => IApprovalStep = (item, data, indentationLevel) => {
    let statusToUse = data.status

    if (item && item.status === 'Canceled' && data.status === 'Open') {
        statusToUse = item.status
    }

    const step: IApprovalStep = {
        stepNumber: data.stepNumber,
        parentStepNumber: data.parentStepNumber,
        friendlyName: data.friendlyName,
        autoApprove: data.autoApprove,
        approvedOnUtc: data.approverResponseDate,
        approverType: data.approverType,
        status: statusToUse,
        indentationLevel: indentationLevel,
        stepId:
            data.stepID === '00000000-0000-0000-0000-000000000000'
                ? null
                : data.stepID,
    }
    if (data.approverPersonId) {
        step.approver = {
            id: data.approverPersonId,
            friendlyName: data.approverPreviewName,
            imageThumbUrl: data.approverPersonImageThumbUrl,
        }
    }
    if (data.riskTypeID) {
        step.risk = {
            riskFriendlyName: data.riskFriendlyName,
            riskDescription: data.riskDescription,
            riskViolator: data.riskViolator,
            riskTypeID: data.riskTypeID,
            riskLevelID: data.riskLevelID,
            riskBusinessRequestID: data.riskBusinessRequestID,
            riskRequestNumber: data.riskRequestNumber,
            riskFunctionSource: convertToSource(data.riskFunctionSource),
            riskFunctionAssignee: data.riskFunctionAssignee,

            segregatedRiskBusinessRequestID:
                data.segregatedRiskBusinessRequestID,
            segregatedRiskRequestNumber: data.segregatedRiskRequestNumber,
            riskSegregatedFunctionSource: convertToSource(
                data.riskSegregatedFunctionSource,
            ),
            riskSegregatedFunctionAssignee: data.riskSegregatedFunctionAssignee,
            riskReason_BusinessRequestID: data.riskReason_BusinessRequestID,
            riskReason_ResourceFriendlyName:
                data.riskReason_ResourceFriendlyName,
        }
    }

    return step
}

const transformItem: (
    data: any,
    indentationLevel: IndentationLevel,
) => IItemWithApprovalSteps = (data, indentationLevel) => {
    const itemWithApprovalSteps: IItemWithApprovalSteps = {
        id: data.businessRequestItemId,
        resourceRequested: data.resourceRequested,
        resourceType: data.resourceType,
        itemTypeActionFriendlyName: data.itemTypeActionFriendlyName,
        approverCount: data.approverCount,
        preApproved: data.preApproved,
        status: data.status,
        globalApprovalSkipped: data.globalApprovalSkipped,
        indentationLevel: indentationLevel,
        arrows: [],
        approvalSteps: data.itemApprovalSteps
            .sort(
                (a: IApprovalStep, b: IApprovalStep) =>
                    parseFloat(a.stepNumber) - parseFloat(b.stepNumber),
            )
            .map((s: any) =>
                transformStep(
                    data,
                    s.step,
                    (indentationLevel + 1) as IndentationLevel,
                ),
            ),
    }
    if (data.claimedApproverId) {
        itemWithApprovalSteps.claimedBy = {
            id: data.claimedApproverId,
            friendlyName: data.claimedApproverFriendlyName,
            imageThumbUrl: '',
        }
    }

    return itemWithApprovalSteps
}

export const transformApprovalStepsResponse: (
    request: any,
    approvalStepsData: any,
    item?: any,
) => IRequestWithApprovalSteps = (request, data, item = undefined) => {
    const percentageToUse = item
        ? item.percentageCompleted
        : request.percentageCompleted

    const globalStepsExist = data.approvalSteps.length > 0

    const requestWithApprovalSteps: IRequestWithApprovalSteps = {
        id: request.id,
        initiator: {
            id: request.initiatorPersonId,
            friendlyName: request.initiatorPersonFriendlyName,
            imageThumbUrl: request.initiatorPersonThumbnailUrl,
        },
        createdDateUtc: request.createdDateUtc,
        approverCount: data.approverCount,
        percentageCompleted: roundOffNumber(percentageToUse * 100, 2),
        preApproved: data.preApproved,
        status: request.status,
        itemTypeCounts: data.itemTypeCount,
        globalApprovalSteps: data.approvalSteps
            .sort(
                (a: IApprovalStep, b: IApprovalStep) =>
                    parseFloat(a.stepNumber) - parseFloat(b.stepNumber),
            )
            .map((gs: any) =>
                transformStep(
                    {
                        status: request.status,
                    },
                    gs.step,
                    0,
                ),
            ),
        items: data.items.map((i: any) =>
            transformItem(i, globalStepsExist ? 1 : 0),
        ),
        arrows: [],
    }
    generateArrows(requestWithApprovalSteps)

    return requestWithApprovalSteps
}

const generateArrows: (request: IRequestWithApprovalSteps) => void = (
    request,
) => {
    request.arrows = []

    for (let i = request.globalApprovalSteps.length - 1; i >= 0; i--) {
        const current = request.globalApprovalSteps[i]

        if (i === 0) {
            request.arrows.push({
                start: 'requestedByNode',
                end: `global_${current.stepNumber}`,
                startAnchor: 'left',
                endAnchor: 'top',
                color: getStatusColor(current.status),
            })
        } else {
            const previous = request.globalApprovalSteps[i - 1]

            request.arrows.push({
                start: `global_${previous.stepNumber}`,
                end: `global_${current.stepNumber}`,
                startAnchor: 'bottom',
                endAnchor: 'top',
                color: getStatusColor(current.status),
            })
        }
    }

    const getLastGlobalApprovalStepStepNumber = () => {
        return request.globalApprovalSteps[
            request.globalApprovalSteps.length - 1
        ].stepNumber
    }

    for (let i = request.items.length - 1; i >= 0; i--) {
        const firstNodeIsGlobal = request.globalApprovalSteps.length > 0

        const currentItem = request.items[i]

        if (i === 0) {
            currentItem.arrows.push({
                start: firstNodeIsGlobal
                    ? `global_${getLastGlobalApprovalStepStepNumber()}`
                    : 'requestedByNode',
                end: `item_${currentItem.id}`,
                startAnchor: firstNodeIsGlobal ? 'bottom' : 'left',
                endAnchor: firstNodeIsGlobal ? 'left' : 'top',
                color: getStatusColor(currentItem.status),
            })
        } else {
            const previousItem = request.items[i - 1]

            currentItem.arrows.push({
                start: firstNodeIsGlobal
                    ? `global_${getLastGlobalApprovalStepStepNumber()}`
                    : `item_${previousItem.id}`,
                end: `item_${currentItem.id}`,
                startAnchor: 'bottom',
                endAnchor: firstNodeIsGlobal ? 'left' : 'top',
                color: getStatusColor(currentItem.status),
            })
        }

        for (let j = currentItem.approvalSteps.length - 1; j >= 0; j--) {
            const currentItemStep = currentItem.approvalSteps[j]

            currentItem.arrows.push({
                start: `item_${currentItem.id}`,
                end: `item_${currentItem.id}_${currentItemStep.stepNumber}`,
                startAnchor: 'bottom',
                endAnchor: 'left',
                color: getStatusColor(currentItemStep.status),
            })
        }
    }
}
