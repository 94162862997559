import { FC, Fragment } from 'react'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { RiskIndicator } from 'packages/eid-ui'
import classNames from 'classnames'
import { mobileScreenWidth } from 'utils'
import { useTranslation } from 'react-i18next'
import { RiskViolationLeftRight } from './RiskViolationLeftRight'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',

            borderRadius: '5px',
            '& >div': {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        },
        header: {
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            paddingTop: '16px',
            paddingBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            overflow: 'hidden',
            backgroundColor: '#307fc1',
            [`@media (max-width:${mobileScreenWidth})`]: {
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            },
        },
        titleSection: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        headerTitle: {
            color: '#ffffff',
            fontSize: '18px',
            fontWeight: 'bold',
        },
        headerSubTitle: {
            color: '#fdcc02',
            fontSize: '14px',
            display: 'flex',
            marginTop: '8px',
            [`@media (max-width:${mobileScreenWidth})`]: {
                lineHeight: 1.7,
                flexWrap: 'wrap',
            },
        },
        riskType: {
            minWidth: '150px',
            marginRight: '12px',
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'flex',
                flexGrow: 1,
            },
        },
        itemFriendlyName: {
            color: '#efeff1',
        },
        riskIndicatorBox: {
            display: 'flex',
            alignItems: 'center',
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'none',
            },
        },
        riskIndicatorMobileContainer: {
            display: 'none',
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'flex',
                alignItems: 'center',
            },
        },

        riskIndicator: {
            backgroundColor: '#fdf1f3',
            opacity: 1,
            fontWeight: 'bold',
        },

        contentClass: {
            backgroundColor: '#ffffff',
        },
        riskContainer: {
            paddingTop: '24px',
        },
        sectionDivider: {
            height: '16px',
            padding: '0 0 15px',
            backgroundImage:
                'linear-gradient(to bottom, #efeff1, rgba(0, 0, 0, 0))',
        },
        removePadding: {
            marginLeft: '-16px',
            marginRight: '-16px',
        },
    }),
)

export type RiskWithViolationsProps = {
    page: string
    requestId?: string
    itemId?: string
    risk: any
}

export const RiskWithViolations: FC<RiskWithViolationsProps> = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const { risk } = props

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Box className={classes.titleSection}>
                    <Typography className={classes.headerTitle}>
                        {risk.localRiskFriendlyName}
                    </Typography>
                    <Box className={classes.headerSubTitle}>
                        <Box className={classes.riskType}>
                            {t(`MyTasks_${risk.riskType}`)}
                        </Box>
                        <Box className={classes.riskIndicatorMobileContainer}>
                            <RiskIndicator
                                variant={risk.riskLevel}
                                label={t(`MyTasks_${risk.riskLevel}Risk`)}
                                className={classes.riskIndicator}
                            />
                        </Box>

                        <Box className={classes.itemFriendlyName}>
                            {risk.itemFriendlyName}
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.riskIndicatorBox}>
                    <RiskIndicator
                        variant={risk.riskLevel}
                        label={t(`MyTasks_${risk.riskLevel}Risk`)}
                        className={classes.riskIndicator}
                    />
                </Box>
            </Box>
            <Box className={classes.contentClass}>
                {risk.violations.map((violation: any, index: number) => (
                    <Fragment key={violation.id}>
                        {index !== 0 && (
                            <Box
                                className={classNames(
                                    classes.sectionDivider,
                                    classes.removePadding,
                                )}
                            />
                        )}
                        <Box className={classes.riskContainer}>
                            <RiskViolationLeftRight
                                page={props.page}
                                requestId={props.requestId}
                                itemId={props.itemId}
                                violation={violation}
                            />
                        </Box>
                    </Fragment>
                ))}
            </Box>
        </Box>
    )
}
