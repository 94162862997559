import { UserManager, WebStorageStateStore } from 'oidc-client'
import { isEmpty, isNil } from 'ramda'

const defaultUserManagerConfig = {
    redirect_uri: `${window.location.origin}/callback`,
    silent_redirect_uri: `${window.location.origin}/renew.html`,
    post_logout_redirect_uri: window.location.origin,
    response_type: 'code',
    scope: 'openid profile email',
    filterProtocolClaims: true,
    loadUserInfo: false,
    automaticSilentRenew: false,
    accessTokenExpiringNotificationTime: 60,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    monitorSession: false,
}

export default (providedConfig) => {
    const config = { ...defaultUserManagerConfig, ...providedConfig }
    if (isEmpty(config.client_id) || isNil(config.client_id)) {
        throw 'Client ID (prop: "client_id") is not provided while setting up AuthContextProvider.'
    }
    if (isEmpty(config.authority) || isNil(config.authority)) {
        throw 'Authority (prop: "authority") is not provided while setting up AuthContextProvider.'
    }
    return new UserManager(config)
}
