import React, { useReducer } from 'react'
import {
    Box,
    styled,
    FormControlLabel,
    makeStyles,
    Checkbox,
} from '@material-ui/core'
import DateTimeFilter from '../Filters/DateTimeFilter'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import { isNilOrEmpty } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'
import moment from 'moment'
import PeopleSearch from '../Filters/PeopleSearch'
import useSubcomponents from 'useSubcomponents'
import { mobileScreenWidth } from 'utils'

const styles = {
    peopleSearch: {
        '& > div': {
            border: 'solid 1px #ebebed',
            '&:hover': {
                borderColor: '#307fc1',
            },
            padding: '1.5px !important',
            '& > div': {
                right: '0px !important',
                pointerEvents: 'none',
            },
            '& > input': {
                height: '35px',
                cursor: 'pointer',
                width: 'auto !important',
            },
        },
    },
    cancelButton: {
        backgroundColor: '#ffffff !important',
        color: '#307fc1 !important',
        boxShadow: 'none',
        border: '1px solid #307fc1',
    },
    checkIcon: {
        position: 'absolute',
        right: '10px',
    },
    listLabel: {
        position: 'absolute',
        top: '-24px',
        left: 5,
        fontSize: '10px',
        color: '#b4b4b4',
        fontWeight: 'bold',
    },
    skeleton: {
        margin: '10px 0px',
        width: '30%',
        borderRadius: '3px',
    },
}

const useStyles = makeStyles(() => ({
    formLabelMargin: {
        marginRight: '30px',
    },

    radio: {
        padding: '0px 12px 0px 0px',
    },

    checked: {
        '&, & + $label': {
            color: '#000000',
        },
    },
    label: {
        color: '#767676',
    },

    dateFilterInput: {
        [`@media (max-width:${mobileScreenWidth})`]: {
            width: '100%',
        },
        '& .Input-Container ': {
            backgroundColor: '#ffffff',
            borderRadius: '5px',
        },
    },
}))
const ItemContainer = styled(Box)({
    padding: '15px 0px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

const reducers = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PERSON':
            return { ...state, person: payload }

        case 'SET_START_DATE':
            return { ...state, startDate: payload }

        case 'SET_END_DATE':
            return { ...state, endDate: payload }

        case 'SET_DELEGATE_FOREVER':
            return {
                ...state,
                endDate: null,
                activeOnlyIfOutOfOffice: false,
                delegateForever: payload,
            }
        case 'SET_OUT_OF_OFFICE':
            return {
                ...state,
                delegateForever: false,
                activeOnlyIfOutOfOffice: payload,
            }

        case 'CLEAR_STATE':
            return {
                ...payload,
            }

        default:
            return state
    }
}

const AssignCurrentStepForm = ({ onSave, isSaving, onCancel }) => {
    const [state, dispatch] = useReducer(reducers, {
        person: [],
        startDate: moment().startOf('day').utc(),
        endDate: null,
        delegateForever: false,
        activeOnlyIfOutOfOffice: false,
        types: [],
    })

    const { t } = useTranslation()
    const classes = useStyles()
    const { hasAccessToOutOfOfficeDelegateControl } = useSubcomponents()

    const endDateCheck = !state.delegateForever && isNilOrEmpty(state.endDate)

    return (
        <>
            <ItemContainer>
                <PeopleSearch
                    endAdornmentComponent={() => <></>}
                    label={t('MyTasks_PersonName')}
                    placeholder={t('MyTasks_SelectPerson')}
                    onChange={(_, value) =>
                        dispatch({
                            type: 'SET_PERSON',
                            payload: value,
                        })
                    }
                    url="/api/people/delegates"
                    rootStyleProps={styles.peopleSearch}
                    value={state.person}
                    multiple
                />
            </ItemContainer>

            <ItemContainer display="flex" marginLeft="12px">
                <FormControlLabel
                    classes={{
                        root: classes.formLabelMargin,
                        label: classes.label,
                    }}
                    control={
                        <Checkbox
                            checked={state.delegateForever}
                            icon={<Icon name="CheckBox" />}
                            checkedIcon={<Icon name="CheckedBox" />}
                            classes={{
                                checked: classes.checked,
                            }}
                            onChange={(e) =>
                                dispatch({
                                    type: 'SET_DELEGATE_FOREVER',
                                    payload: e.target.checked,
                                })
                            }
                        />
                    }
                    label={t('MyTasks_DelegateForever')}
                />
                {hasAccessToOutOfOfficeDelegateControl && (
                    <FormControlLabel
                        classes={{ label: classes.label }}
                        control={
                            <Checkbox
                                classes={{ checked: classes.checked }}
                                checked={state.activeOnlyIfOutOfOffice}
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={<Icon name="CheckedBox" />}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'SET_OUT_OF_OFFICE',
                                        payload: e.target.checked,
                                    })
                                }
                            />
                        }
                        label={t('MyTasks_OnlyWhenOutOfOffice')}
                    />
                )}
            </ItemContainer>

            <ItemContainer display="flex" marginLeft="-8px">
                <Box
                    padding="0px 8px"
                    classes={{ root: classes.dateFilterInput }}
                >
                    <DateTimeFilter
                        disablePast={true}
                        margin="normal"
                        label={t('MyTasks_StartDate')}
                        placeholder={t('MyTasks_SelectDate')}
                        value={
                            isNilOrEmpty(state.startDate)
                                ? null
                                : state.startDate.clone().local()
                        }
                        onChange={(date) => {
                            const dateToSet = date.clone().startOf('day').utc()
                            dispatch({
                                type: 'SET_START_DATE',
                                payload: dateToSet,
                            })
                        }}
                        handleClear={() =>
                            dispatch({
                                type: 'SET_START_DATE',
                                payload: null,
                            })
                        }
                    />
                </Box>

                {!state.delegateForever && (
                    <Box
                        padding="0px 8px "
                        classes={{ root: classes.dateFilterInput }}
                    >
                        <DateTimeFilter
                            minDate={
                                !isNilOrEmpty(state.startDate) &&
                                state.startDate.clone().local()
                            }
                            disabled={state.delegateForever}
                            label={t('MyTasks_EndDate')}
                            placeholder={t('MyTasks_SelectDate')}
                            value={
                                isNilOrEmpty(state.endDate)
                                    ? null
                                    : state.endDate.clone().local()
                            }
                            onChange={(date) => {
                                const dateToSet = date
                                    .clone()
                                    .endOf('day')
                                    .set('milliseconds', 0)
                                    .utc()

                                dispatch({
                                    type: 'SET_END_DATE',
                                    payload: dateToSet,
                                })
                            }}
                            handleClear={() =>
                                dispatch({
                                    type: 'SET_END_DATE',
                                    payload: null,
                                })
                            }
                        />
                    </Box>
                )}
            </ItemContainer>

            <ItemContainer
                display="flex"
                marginLeft="-8px"
                alignItems="center"
                flexDirection="row !important"
                flexWrap="wrap"
            >
                <Tooltip
                    title={
                        isNilOrEmpty(state.startDate) ||
                        isNilOrEmpty(state.person) ||
                        endDateCheck
                            ? t('MyTasks_RequiredFieldsMissing')
                            : ''
                    }
                >
                    <Box>
                        <Button.Rounded
                            disabled={
                                isSaving ||
                                isNilOrEmpty(state.startDate) ||
                                isNilOrEmpty(state.person) ||
                                endDateCheck
                            }
                            onClick={() => {
                                onSave(state)
                            }}
                            isLoading={isSaving}
                        >
                            {t('MyTasks_CreateDelegation')}
                        </Button.Rounded>
                    </Box>
                </Tooltip>

                <Button.Rounded onClick={onCancel} styles={styles.cancelButton}>
                    {t('MyTasks_Cancel')}
                </Button.Rounded>
            </ItemContainer>
        </>
    )
}

export default AssignCurrentStepForm
