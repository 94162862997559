import { RESET_AUTH_STATE, USER_LOGGED_IN } from './constants'

export function resetAuthState() {
    return {
        type: RESET_AUTH_STATE,
    }
}
export function userLoggedIn(payload) {
    return {
        type: USER_LOGGED_IN,
        payload,
    }
}

export default {
    resetAuthState,
    userLoggedIn,
}
