import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import ItemHeader from './ItemHeader'
import Attributes from './Attributes'

const useCardStyles = makeStyles({
    root: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
        border: 'none !important',
        borderRadius: '8px !important',
        paddingBottom: '12px',
        ...props.cardStyles,
    }),
})

const styles = {
    viewDetails: { margin: '16px 20px' },
}

const ItemCard = (props) => {
    const {
        item,
        onItemClick,
        buttonStyles,
        cardStyles,
        attributes,
        color,
        headerStyles,
    } = props

    const { t } = useTranslation()
    const cardClasses = useCardStyles({ cardStyles })
    const headerAttribute = attributes.find((x) => x.isHeader)

    return (
        <Card classes={cardClasses} role="button">
            <ItemHeader
                title={item[headerAttribute.name]}
                styles={headerStyles}
            />
            <Attributes item={item} attributes={attributes} color={color} />

            <Button.ViewDetails
                styles={{ ...styles.viewDetails, ...buttonStyles }}
                onClick={onItemClick}
            >
                {t('MyTasks_ViewDetails')}
            </Button.ViewDetails>
        </Card>
    )
}

ItemCard.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
}

export default ItemCard
