import React from 'react'
import { usePageContext } from 'pageContext'
import { PersonFilter } from 'components'
import { useAuthState } from 'packages/core'
import { useTranslation } from 'react-i18next'
import {
    AttributeContainer,
    AttributeValue,
    getLocationString,
    styles,
    VerticalAlignedLabel,
} from 'components/DetailsComponents'
import { Avatar as MuiAvatar, Box, makeStyles } from '@material-ui/core'
import { getFullImageUrl, smallScreenWidth } from 'utils'

export const Filter = () => {
    const [state, dispatch]: any = usePageContext()
    const [{ currentUserId, friendlyName }]: any = useAuthState()

    return (
        <PersonFilter
            value={state.subject}
            onClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'subject',
                        value: {
                            id: currentUserId,
                            friendlyName: friendlyName,
                        },
                    },
                })
            }
            onChange={(payload: any) => {
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'subject',
                        value: payload,
                    },
                })
            }}
        />
    )
}

const useStyles = makeStyles({
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        [`@media (max-width:${smallScreenWidth})`]: {
            display: 'flex',
            marginLeft: '60px',
        },
    },
    avatar: {
        width: '152px',
        height: '153px',
    },
})

export const Details = ({ accessRecipient }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <>
            <Box
                className={classes.avatarContainer}
                marginBottom="24px !important"
            >
                <MuiAvatar
                    src={getFullImageUrl(accessRecipient.personPhotoUrl)}
                    className={classes.avatar}
                />
            </Box>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_AccessRecipient')}
                </VerticalAlignedLabel>

                <AttributeValue
                    value={accessRecipient.friendlyName}
                    style={styles.roleReceiverValue}
                    noWrap
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_Email')}
                </VerticalAlignedLabel>
                <AttributeValue value={accessRecipient.email} noWrap />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_Login')}
                </VerticalAlignedLabel>

                <AttributeValue
                    value={accessRecipient.login}
                    noWrap
                    style={styles.locationValue}
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_Location')}
                </VerticalAlignedLabel>

                <AttributeValue
                    value={getLocationString(
                        accessRecipient.city,
                        accessRecipient.country,
                    )}
                    noWrap
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_Manager')}
                </VerticalAlignedLabel>
                <AttributeValue value={accessRecipient.managerName} noWrap />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_JobFunction')}
                </VerticalAlignedLabel>
                <AttributeValue value={accessRecipient.jobTitle} noWrap />
            </AttributeContainer>
        </>
    )
}
