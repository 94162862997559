import React from 'react'
import RequestsLayout from './RequestsLayout'
import { useMyRequests, useMyRequestsTasks, useAllRequests } from 'hooks'
import useSubcomponents from 'useSubcomponents'

const allFilters = {
    myRequests: [
        {
            name: 'requestedBy',
            requireAccess: 'MyTasks-MyRequestsRequestedByFilter-Control',
        },
        {
            name: 'subjectType',
            requireAccess: 'MyTasks-MyRequestsRequestedForFilter-Control',
        },
        {
            name: 'approver',
            requireAccess: 'MyTasks-MyRequestsApproverFilter-Control',
        },
        {
            name: 'preApproved',
            requireAccess: 'MyTasks-ShowPreApprovedFilter-Control',
        },
        {
            name: 'requestStatus',
            requireAccess: 'MyTasks-MyRequestsRequestViewStatusFilter-Control',
        },
        {
            name: 'itemType',
            requireAccess: 'MyTasks-MyRequestsItemTypeFilter-Control',
        },
        {
            name: 'riskLevel',
            requireAccess: 'MyTasks-RiskLevelFilter-Control',
        },
        {
            name: 'requestsAdvancedSearch',
            requireAccess:
                'MyTasks-MyRequestsRequestViewAdvancedSearchFilter-Control',
        },
    ],
    myTasks: [
        {
            name: 'requestedBy',
            requireAccess: 'MyTasks-MyTasksRequestedByFilter-Control',
        },
        {
            name: 'subjectType',
            requireAccess: 'MyTasks-MyTasksRequestedForFilter-Control',
        },
        {
            name: 'itemType',
            requireAccess: 'MyTasks-MyTasksItemTypeFilter-Control',
        },
        {
            name: 'riskLevel',
            requireAccess: 'MyTasks-RiskLevelFilter-Control',
        },
        {
            name: 'requestsAdvancedSearch',
            requireAccess:
                'MyTasks-MyTasksRequestViewAdvancedSearchFilter-Control',
        },
    ],
    allRequests: [
        {
            name: 'requestedBy',
            requireAccess: 'MyTasks-AllRequestsRequestedByFilter-Control',
        },
        {
            name: 'subjectType',
            requireAccess: 'MyTasks-AllRequestsRequestedForFilter-Control',
        },
        {
            name: 'approver',
            requireAccess: 'MyTasks-AllRequestsApproverFilter-Control',
        },
        {
            name: 'preApproved',
            requireAccess: 'MyTasks-ShowPreApprovedFilter-Control',
        },
        {
            name: 'requestStatus',
            requireAccess: 'MyTasks-AllRequestsRequestViewStatusFilter-Control',
        },
        {
            name: 'itemType',
            requireAccess: 'MyTasks-AllRequestsItemTypeFilter-Control',
        },
        {
            name: 'riskLevel',
            requireAccess: 'MyTasks-RiskLevelFilter-Control',
        },
        {
            name: 'requestsAdvancedSearch',
            requireAccess:
                'MyTasks-AllRequestsRequestViewAdvancedSearchFilter-Control',
        },
    ],
}

const RequestViewMyRequests = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useMyRequests()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.myRequests).map(
        (f) => f.name,
    )

    return (
        <RequestsLayout
            totalCount={totalCount}
            list={list}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
        />
    )
}

const RequestViewMyTasks = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useMyRequestsTasks()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.myTasks).map(
        (f) => f.name,
    )

    return (
        <RequestsLayout
            totalCount={totalCount}
            list={list}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
        />
    )
}

const RequestViewAllRequests = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useAllRequests()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.allRequests).map(
        (f) => f.name,
    )

    return (
        <RequestsLayout
            list={list}
            totalCount={totalCount}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
        />
    )
}

export { RequestViewMyRequests, RequestViewMyTasks, RequestViewAllRequests }
