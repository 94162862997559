import React, { forwardRef, Fragment } from 'react'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import PersonDetails from './PersonDetails'
import { ItemTypeCount } from 'components'
import { Divider } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { DateTime } from './utils'
import moment from 'moment'
import { IRequestWithApprovalSteps } from 'types'
import { getFullImageUrl } from 'utils'

const useContainerStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            '& *': {
                zIndex: 2,
            },
        },
    }),
)

const styles = {
    preApprovedStatus: {
        color: '#01ae8f',
        fontSize: '14px',
    },
    divider: {
        margin: '0px 10px',
        color: '#d8d8dd',
        width: '16px',
        height: '1px',
    },
}

const RequestedByNode = forwardRef<
    HTMLDivElement,
    {
        id: string
        request: IRequestWithApprovalSteps
    }
>((props, ref) => {
    const { request, ...rest } = props
    const { t } = useTranslation()
    const classes = useContainerStyles()

    return (
        <div className={classes.root} ref={ref}>
            <Box width="33%" />
            <Box width="33.33%">
                <Box
                    display="flex"
                    alignItems="center"
                    position="relative"
                    paddingBottom="16px"
                    flexDirection="column"
                >
                    <PersonDetails
                        avatarId={props.id}
                        name={request.initiator.friendlyName}
                        imageUrl={getFullImageUrl(
                            request.initiator.imageThumbUrl,
                        )}
                        requestedBy={t('MyTasks_RequestedBy')}
                        moreInfo={
                            request.preApproved && (
                                <Fragment>
                                    <Divider vertical style={styles.divider} />
                                    <Typography
                                        style={styles.preApprovedStatus}
                                    >
                                        {t('MyTasks_PreApproved')}
                                    </Typography>
                                </Fragment>
                            )
                        }
                        {...rest}
                    />
                    <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        paddingLeft="50px"
                        width="100%"
                    >
                        <Box
                            display="flex"
                            flexWrap="wrap"
                            alignItems="center"
                            width="340px"
                        >
                            <ItemTypeCount
                                requestId={request.id}
                                itemTypeCount={request.itemTypeCounts}
                            />
                        </Box>
                        {request.createdDateUtc && (
                            <Box display="flex" paddingTop="2px">
                                <Icon
                                    height="16px"
                                    width="16px"
                                    name="Clock"
                                    color="#aab0b4"
                                />

                                <DateTime noWrap>
                                    {moment
                                        .utc(request.createdDateUtc)
                                        .local()
                                        .format('LLL')}
                                </DateTime>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box width="33.33%" />
        </div>
    )
})

export { RequestedByNode }
