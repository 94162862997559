import { Box, createStyles, makeStyles } from '@material-ui/core'
import { RiskWithViolations } from './RiskWithViolations'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import React, { FC } from 'react'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '8px',
        },
        riskContainer: {
            margin: '20px 20px',
            width: '100%',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        riskDivider: {
            borderImageSource:
                'linear-gradient(to right, rgba(208, 2, 27, 0), #d0021b 8%, #d00238 91%, rgba(208, 2, 69, 0))',
            borderImageSlice: 1,
            borderBottom: '1px solid',
            opacity: '0.25',
        },
    }),
)

export type RisksWithViolationsProps = {
    page: string
    requestId?: string
    itemId?: string
    requestIdOrItemId: string
    useDataHook: any
}

export const RisksWithViolations: FC<RisksWithViolationsProps> = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const {
        data,
        isLoading,
    }: { data: any; isLoading: boolean } = props.useDataHook(
        props.requestIdOrItemId,
        'Risks',
    )

    if (isLoading || !data) {
        return (
            <Box marginX={4} marginY={4}>
                <Skeleton animation="wave" variant="rect" height={24} />
                <Box marginY={1} />
                <Skeleton animation="wave" variant="rect" height={24} />
            </Box>
        )
    } else if (data.length === 0) {
        return (
            <Box
                marginX={4}
                marginY={4}
                display={'flex'}
                justifyContent={'center'}
            >
                {t('MyTasks_NoDataFound')}
            </Box>
        )
    }

    return (
        <Box className={classes.root}>
            {data.map((risk: any) => (
                <Box key={risk.id} className={classes.riskContainer}>
                    <RiskWithViolations
                        page={props.page}
                        requestId={props.requestId}
                        itemId={props.itemId}
                        risk={risk}
                    />
                    <Box className={classes.riskDivider} />
                </Box>
            ))}
        </Box>
    )
}
