import React from 'react'
import * as Icons from './'

export default ({ name, ...rest }) => {
    const IconComponent = Icons[`${name}Icon`]
    if (IconComponent === undefined) {
        console.warn(`Icon with name: ${name} not found.`)
        return <></>
    }
    return <IconComponent {...rest} />
}
