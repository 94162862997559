import React from 'react'
import Button from './index'
import { Icon } from 'packages/eid-icons'

const styles = {
    root: {
        minWidth: '100px',
        minHeight: '25px',
        padding: '1px 8px',
        fontSize: '12px',
        fontWeight: 'bold',
        textTransform: 'upper-case',
        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    decision: {
        backgroundColor: '#e7f4ff',
        border: '1px solid  #307fc1',
        color: '#307fc1',
        '& svg': {
            color: '#307fc1',
        },

        '&:hover': {
            backgroundColor: '#e7f4ff',
        },
    },
    Approve: {
        backgroundColor: '#e1fee6 ',
        border: '1px solid #1eb77b',
        color: '#00bb75',
        fontWeight: 'bold',
        '& svg': {
            color: '#00bb75',
        },
        '&:hover': {
            backgroundColor: '#e1fee6 ',
        },
    },
    Reject: {
        backgroundColor: '#f9dada',
        border: '1px solid  #d0021b',
        color: '#d0021b',
        fontWeight: 'bold',
        '& svg': {
            color: '#d0021b',
        },
        '&:hover': {
            backgroundColor: '#f9dada',
        },
    },

    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },
}

const DecisionButton = ({ endIcon, active, icon, variant, ...rest }) => {
    return (
        <Button
            size="small"
            style={{ ...styles.root, ...styles[variant] }}
            disabledStyles={styles.disabled}
            startIcon={
                !endIcon &&
                (icon ? (
                    icon
                ) : (
                    <Icon
                        name="Arrow"
                        color="#ffffff"
                        width="14px"
                        height="14px"
                    />
                ))
            }
            endIcon={
                endIcon &&
                (icon ? (
                    icon
                ) : (
                    <Icon
                        name="Arrow"
                        color="#ffffff"
                        width="14px"
                        height="14px"
                    />
                ))
            }
            {...rest}
        />
    )
}

export default DecisionButton
