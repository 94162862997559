import React from 'react'
import {
    styled,
    makeStyles,
    CardHeader as MuiCardHeader,
    IconButton,
    Typography,
    Box,
} from '@material-ui/core/'
import { ArrowIcon } from 'packages/eid-icons'

const useStyles = makeStyles((theme) => ({
    root: ({ headerColor, colorCode, style }) => ({
        display: 'flex',
        height: '60px',
        padding: '0 10px 0 20px',
        borderBottom: headerColor && '1px solid #efeff1',
        borderLeft: colorCode && `3px solid ${colorCode}`,
        backgroundColor: headerColor ? headerColor : '#ffffff',
        ...style,
    }),
    title: ({ fontColor }) => ({
        color: fontColor,
        '& button': {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    }),
    avatar: {
        width: '18px',
        height: '18px',
    },
    content: {
        width: '100%',
    },
}))

const ColorCode = styled(({ colorCode, ...rest }) => <div {...rest} />)(
    ({ colorCode }) => ({
        width: '8px',
        height: '8px',
        backgroundColor: colorCode,
        borderRadius: '50%',
        marginLeft: '8px',
    }),
)

const CardHeader = ({
    cardTitle,
    handleExpandClick,
    expanded,
    collapsible,
    headerColor,
    fontColor,
    icon,
    colorCode,
    style,
}) => {
    const classes = useStyles({
        headerColor,
        fontColor,
        icon,
        colorCode,
        style,
    })
    return (
        <>
            <MuiCardHeader
                avatar={icon}
                title={
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center" width="100%">
                            <Typography style={{ maxWidth: '90%' }}>
                                {cardTitle}
                            </Typography>
                            <ColorCode colorCode={colorCode} />
                        </Box>
                        {collapsible && (
                            <IconButton
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                                disableFocusRipple
                                disableRipple
                            >
                                <ArrowIcon
                                    direction={expanded ? 'up' : 'down'}
                                />
                            </IconButton>
                        )}
                    </Box>
                }
                titleTypographyProps={{ variant: 'h2' }}
                classes={classes}
            ></MuiCardHeader>
        </>
    )
}

export default CardHeader
