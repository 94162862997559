import { Box, Divider } from '@material-ui/core'
import { StatusIndicator } from 'packages/eid-ui'
import { Link, useHistory } from 'react-router-dom'
import { OverviewSectionSkeleton, Person, ProgressIndicator, TextFormatter, } from 'components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { usePerson, useRequestItemAccessRecipient, useRequestItemEditFulfillmentDate } from 'hooks'
import {
    AttributeContainer,
    AttributeLabel,
    AttributeValue,
    Container,
    styles,
    useStyles,
    VerticalAlignedLabel,
} from 'components/DetailsComponents'
import {getFullImageUrl, roundOffNumber} from 'utils'
import {useRegistry} from 'core/useRegistry'
import useSubcomponents from 'useSubcomponents'
import {FulfillmentScheduleDateControl} from 'components/FulfillmentScheduleDateControl'

const statusStyles = {
    color: '#9b9b9b',
    maxWidth: '270px',
}

const Overview = ({ data }) => {
    const registry = useRegistry()
    const { t } = useTranslation()

    const history = useHistory()
    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1]

    const classes = useStyles()

    const {
        hasAccessToFulfillmentScheduleDateControl,
        hasAccessToEditFulfillmentScheduleDateControl,
    } = useSubcomponents()

    const { data: accessRecipient } = useRequestItemAccessRecipient(
        data?.id,
        data?.businessRequestId,
    )

    const { data: initiatorPerson } = usePerson(data?.initiatorPersonId)

    const itemStatus = data?.preApproved ? 'PreApproved' : data?.status

    const [editFulfillmentDate] =
        useRequestItemEditFulfillmentDate(data.id)


    if (!data || !accessRecipient) {
        return (
            <Container>
                <OverviewSectionSkeleton />
            </Container>
        )
    }

    const subjectTypeInstance = registry.getSubjectType(
        accessRecipient.subjectType,
    )
    const SubjectDetailsComponent = subjectTypeInstance.detailComponent

    return (
        <Container>
            <Box classes={{ root: classes.profileContainer }}>
                <SubjectDetailsComponent accessRecipient={accessRecipient} />

                {data.startDateUtc && (
                    <AttributeContainer>
                        <AttributeLabel>
                            {t('MyTasks_AccessStartDate')}
                        </AttributeLabel>

                        <AttributeValue
                            value={moment
                                .utc(data.startDateUtc)
                                .local()
                                .format('LLL')}
                        />
                    </AttributeContainer>
                )}
                {data.endDateUtc && (
                    <AttributeContainer>
                        <AttributeLabel>
                            {t('MyTasks_AccessEndDate')}
                        </AttributeLabel>

                        <AttributeValue
                            value={moment
                                .utc(data.endDateUtc)
                                .local()
                                .format('LLL')}
                        />
                    </AttributeContainer>
                )}
            </Box>

            <Divider orientation="vertical" flexItem style={styles.divider} />

            <Box classes={{ root: classes.detailsContainer }}>
                {data?.currentApprovalStepName && (
                    <AttributeContainer>
                        <AttributeLabel>{t('MyTasks_Step')}</AttributeLabel>
                        <AttributeValue
                            style={{
                                fontWeight: 700,
                            }}
                            value={data?.currentApprovalStepName}
                        />
                    </AttributeContainer>
                )}
                <AttributeContainer
                    classes={{ root: classes.progressAttribute }}
                >
                    <VerticalAlignedLabel
                        style={{
                            color: '#5d6870',
                            fontWeight: '600',
                        }}
                    >
                        {t('MyTasks_PercentageCompleted', {
                            value: `${roundOffNumber(
                                data?.percentageCompleted * 100,
                            )} %`,
                        })}
                    </VerticalAlignedLabel>

                    <Box
                        classes={{
                            root: classes.progressIndicatorContainer,
                        }}
                    >
                        <Box>
                            <ProgressIndicator
                                percentageCompleted={
                                    data?.percentageCompleted * 100
                                }
                            />
                        </Box>
                        <Box display="flex">
                            <StatusIndicator
                                size="large"
                                variant={itemStatus}
                                label={t(`MyTasks_${itemStatus}`)}
                            />
                        </Box>
                    </Box>
                </AttributeContainer>
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_Requestor')}
                    </VerticalAlignedLabel>
                    <Box classes={{ root: classes.person }}>
                        <Person.Details
                            imgSrc={getFullImageUrl(
                                initiatorPerson?.imageThumbUrl,
                            )}
                            size="large"
                            name={
                                initiatorPerson
                                    ? initiatorPerson.friendlyName
                                    : data?.initiatorPersonFriendlyName
                            }
                            status={initiatorPerson?.login}
                            statusStyles={statusStyles}
                            showTooltip={true}
                        />
                    </Box>
                </AttributeContainer>
                <AttributeContainer>
                    <AttributeLabel>{t('MyTasks_RequestID')}</AttributeLabel>
                    <AttributeValue value={data?.businessRequestNumber} />
                </AttributeContainer>
                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_BusinessRequestName')}
                    </AttributeLabel>

                    <Link
                        to={`/${areaName}/businessRequests?id=${data.businessRequestId}`}
                        style={styles.linkStyles}
                    >
                        <TextFormatter
                            value={data?.businessRequestName}
                            style={styles.linkStyles}
                            maxCharacters={100}
                            showTooltip="always"
                        />
                    </Link>
                </AttributeContainer>
                {data.businessRequestStatus && (
                    <AttributeContainer>
                        <AttributeLabel>
                            {t('MyTasks_RequestStatus')}
                        </AttributeLabel>

                        <AttributeValue
                            value={
                                <StatusIndicator
                                    size="small"
                                    variant={data.businessRequestStatus}
                                    label={t(
                                        `MyTasks_${data.businessRequestStatus}`,
                                    )}
                                />
                            }
                        />
                    </AttributeContainer>
                )}

                {(itemStatus === 'Approved' || itemStatus === 'PreApproved') &&
                    data?.fulfillmentStatusFriendlyName && (
                        <AttributeContainer>
                            <AttributeLabel>
                                {t('MyTasks_FulfillmentStatus')}
                            </AttributeLabel>

                            <AttributeValue
                                value={
                                    <StatusIndicator
                                        label={`${t('MyTasks_Fulfillment')} ${
                                            data?.fulfillmentStatusFriendlyName
                                        }`}
                                        size="small"
                                    />
                                }
                            />
                        </AttributeContainer>
                    )}

                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_TargetResource')}
                    </AttributeLabel>

                    <TextFormatter
                        value={data?.resourceRequested}
                        style={styles.attributeValue}
                        maxCharacters={100}
                        showTooltip="always"
                    />
                </AttributeContainer>

                {hasAccessToFulfillmentScheduleDateControl &&
                    data?.fulFillmentScheduleDateUtc && (
                        <AttributeContainer>
                            <AttributeLabel>
                                {t('MyTasks_FulfillmentScheduleDate')}
                            </AttributeLabel>
                            <AttributeValue
                                value={
                                    !data || !data.fulFillmentScheduleDateUtc
                                        ? ''
                                        : moment
                                              .utc(
                                                  data.fulFillmentScheduleDateUtc,
                                              )
                                              .local()
                                              .format('LLL')
                                }
                            ></AttributeValue>
                        </AttributeContainer>
                    )}

                {(hasAccessToFulfillmentScheduleDateControl ||
                    hasAccessToEditFulfillmentScheduleDateControl) &&
                    data?.fulFillmentScheduleDateUtc && (
                        <AttributeContainer>
                            <AttributeLabel>
                                {t('MyTasks_FulfillmentScheduleDate')}
                            </AttributeLabel>

                            <FulfillmentScheduleDateControl
                                item={data}
                                onEdit={editFulfillmentDate}
                                withOutLabel={true}
                            />
                        </AttributeContainer>
                    )}

                <AttributeContainer>
                    <AttributeLabel>{t('MyTasks_CreatedOn')}</AttributeLabel>
                    <AttributeValue
                        value={
                            !data
                                ? ''
                                : moment
                                      .utc(data.createdDateUtc)
                                      .local()
                                      .format('LLL')
                        }
                    />
                </AttributeContainer>

                {data?.dueDateUtc && (
                    <AttributeContainer>
                        <AttributeLabel>{t('MyTasks_DueDate')}</AttributeLabel>
                        <AttributeValue
                            value={
                                !data || !data.dueDateUtc
                                    ? ''
                                    : moment
                                          .utc(data.dueDateUtc)
                                          .local()
                                          .format('LLL')
                            }
                        />
                    </AttributeContainer>
                )}

                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_LastModifiedOn')}
                    </AttributeLabel>
                    <AttributeValue
                        value={
                            !data
                                ? ''
                                : moment
                                      .utc(data.modifiedDateUtc)
                                      .local()
                                      .format('LLL')
                        }
                    />
                </AttributeContainer>

                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_ExpirationDate')}
                    </AttributeLabel>

                    <AttributeValue
                        value={
                            !data
                                ? ''
                                : moment
                                      .utc(data.expirationDateUtc)
                                      .local()
                                      .format('LLL')
                        }
                    />
                </AttributeContainer>

                {data.additionalProperties &&
                    Object.keys(data.additionalProperties).map((k) => (
                        <AttributeContainer key={k}>
                            <AttributeLabel>{t(`MyTasks_${k}`)}</AttributeLabel>

                            <AttributeValue
                                value={data.additionalProperties[k]?.toString()}
                            />
                        </AttributeContainer>
                    ))}
            </Box>
        </Container>
    )
}

export default Overview
