import React, { FC, Fragment, useEffect, useState } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { PillTabs } from 'components/PillTabs'
import { Tab } from 'components/PillTabs/Tab'
import { TabLabel } from 'components/PillTabs/TabLabel'
import { useTranslation } from 'react-i18next'
import { useRequestPendingItems, useRequestPendingRiskItems } from 'hooks'
import { isNilOrEmpty } from 'packages/core'
import { Pagination } from 'packages/eid-ui'
import { Search } from 'components'
import { PendingItems } from './PendingItems'
import { Skeleton } from '@material-ui/lab'
import { mobileScreenWidth } from 'utils'
import { PendingRiskItems } from 'components/V2/PendingRiskItems'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginTop: '1px',
        },
        tabsContainer: {
            display: 'flex',
            [`@media (max-width:${mobileScreenWidth}) `]: {
                display: 'block',
            },
        },
        tabsSearchContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#FAFAFC',
            borderBottom: 'solid 1px #d8d8dd',
            alignItems: 'flex-end',
            paddingBottom: '8px',
            '& > *': {
                marginRight: '26px',
                width: '309px',
                display: 'flex',
                alignItems: 'center',
                [`@media (max-width:${mobileScreenWidth}) `]: {
                    width: '100%',
                    marginLeft: '26px',
                    marginTop: '8px',
                },
            },
        },
    }),
)

const pendingItemsTake = 10

export interface RequestToDoTabsProps {
    data: any
}

export type TabType = undefined | 'none' | 'pendingRiskItems' | 'pendingItems'

const RequestToDoTabs: FC<RequestToDoTabsProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const [activeTab, setActiveTab] = useState<TabType>(undefined)

    const [pendingItemsPage, setPendingItemsPage] = useState(1)
    const [pendingItemsSearchKey, setPendingItemsSearchKey] = useState('')

    const { latestData: pendingItemsLatestData } = useRequestPendingItems(
        props.data.id,
        (pendingItemsPage - 1) * pendingItemsTake,
        pendingItemsTake,
        pendingItemsSearchKey,
    )

    const pendingItemsTotalCount = pendingItemsLatestData?.totalCount

    const {
        data: pendingRiskItems,
        totalCount: pendingRiskItemsTotalCount,
    } = useRequestPendingRiskItems(props.data.id)

    useEffect(() => {
        if (
            pendingItemsTotalCount === undefined ||
            pendingRiskItemsTotalCount === undefined
        )
            return

        if (activeTab === undefined) {
            if (pendingRiskItemsTotalCount > 0) {
                setActiveTab('pendingRiskItems')
            } else if (pendingItemsTotalCount > 0) {
                setActiveTab('pendingItems')
            } else {
                setActiveTab('none')
            }
        }
    }, [activeTab, pendingItemsTotalCount, pendingRiskItemsTotalCount])

    useEffect(() => {
        if (
            pendingItemsTotalCount === undefined ||
            pendingRiskItemsTotalCount === undefined
        )
            return

        if (activeTab === 'pendingRiskItems') {
            if (pendingRiskItemsTotalCount === 0) {
                if (pendingItemsTotalCount > 0) {
                    setActiveTab('pendingItems')
                } else {
                    setActiveTab('none')
                }
            }
        }
    }, [activeTab, pendingItemsTotalCount, pendingRiskItemsTotalCount])

    useEffect(() => {
        if (
            pendingItemsTotalCount === undefined ||
            pendingRiskItemsTotalCount === undefined
        )
            return

        if (activeTab === 'pendingItems') {
            if (pendingItemsTotalCount === 0) {
                if (pendingRiskItemsTotalCount > 0) {
                    setActiveTab('pendingRiskItems')
                } else {
                    setActiveTab('none')
                }
            }
        }
    }, [activeTab, pendingItemsTotalCount, pendingRiskItemsTotalCount])

    useEffect(() => {
        if (
            pendingItemsTotalCount === undefined ||
            pendingRiskItemsTotalCount === undefined
        )
            return

        if (activeTab === 'none') {
            if (pendingRiskItemsTotalCount > 0) {
                setActiveTab('pendingRiskItems')
            } else if (pendingItemsTotalCount > 0) {
                setActiveTab('pendingItems')
            } else {
                setActiveTab('none')
            }
        }
    }, [activeTab, pendingItemsTotalCount, pendingRiskItemsTotalCount])

    const pendingItems = pendingItemsLatestData?.data

    const pendingItemsNoOfPages = pendingItemsLatestData
        ? Math.ceil(pendingItemsLatestData.totalCount / pendingItemsTake)
        : 0

    const showPendingItems =
        !pendingItemsLatestData ||
        pendingItemsLatestData.totalCount > 0 ||
        !isNilOrEmpty(pendingItemsSearchKey)

    const showPendingRiskItems =
        !pendingRiskItems || (pendingRiskItemsTotalCount as number) > 0

    if (activeTab === undefined) {
        return (
            <Box marginX={4} marginY={4}>
                <Skeleton animation="wave" variant="rect" height={24} />
                <Box marginY={1} />
                <Skeleton animation="wave" variant="rect" height={24} />
            </Box>
        )
    }

    if (activeTab === 'none' || (!showPendingItems && !showPendingRiskItems)) {
        return <Fragment />
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.tabsContainer}>
                <PillTabs
                    value={activeTab}
                    onChange={(_, newValue: string) =>
                        setActiveTab(newValue as TabType)
                    }
                >
                    {showPendingRiskItems && (
                        <Tab
                            label={
                                <TabLabel
                                    label={t('MyTasks_RiskViolationsToDo')}
                                    count={pendingRiskItemsTotalCount}
                                />
                            }
                            value={'pendingRiskItems'}
                        />
                    )}
                    {showPendingItems && (
                        <Tab
                            label={
                                <TabLabel
                                    label={t('MyTasks_ToDo')}
                                    count={pendingItemsLatestData?.totalCount}
                                />
                            }
                            value={'pendingItems'}
                        />
                    )}
                </PillTabs>
                <Box className={classes.tabsSearchContainer}>
                    {activeTab === 'pendingItems' && (
                        <Search
                            outlined
                            placeholder={t('MyTasks_Search')}
                            value={pendingItemsSearchKey}
                            handleClear={() => setPendingItemsSearchKey('')}
                            handleSearch={(value: any) => {
                                setPendingItemsPage(1)
                                setPendingItemsSearchKey(value)
                            }}
                        />
                    )}
                </Box>
            </Box>
            {activeTab === 'pendingRiskItems' && showPendingRiskItems && (
                <Box marginY={'16px'}>
                    <PendingRiskItems
                        page={'Requests'}
                        requestId={props.data.id}
                        data={pendingRiskItems}
                    />
                </Box>
            )}

            {activeTab === 'pendingItems' && (
                <Fragment>
                    <PendingItems
                        businessRequest={props.data}
                        data={pendingItems}
                        isLoading={!Boolean(pendingItemsLatestData)}
                        id={props.data.id}
                        totalCount={
                            pendingItemsLatestData &&
                            pendingItemsLatestData.totalCount
                        }
                        callApiOnEveryChange={
                            props.data.status === 'Open_PendingApproval'
                        }
                        onSuccess={() => setPendingItemsPage(1)}
                    />
                    {pendingItemsNoOfPages > 1 && (
                        <Box display="flex" justifyContent="center" my={2}>
                            <Pagination
                                count={pendingItemsNoOfPages}
                                size="small"
                                page={pendingItemsPage}
                                onChange={(_: any, newValue: any) =>
                                    setPendingItemsPage(newValue)
                                }
                            />
                        </Box>
                    )}
                </Fragment>
            )}
        </Box>
    )
}

export default RequestToDoTabs
