import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { IconButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    iconButton: ({ expanded }) => ({
        marginBottom: '-14px',
        borderRadius: '5px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',

        display: 'flex',
        marginLeft: '4px',
        marginRight: '4px',
        margin: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
        minWidth: '60px',
        backgroundColor: expanded ? '#efeff1' : 'inherit',
        '&:hover': {
            backgroundColor: '#efeff1 !important',
        },
    }),
    violationsIconContainer: {
        height: '24px',
        width: '24px',
        bgcolor: 'rgba(208, 2, 27, 0.07)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px #d0021b solid',
        borderRadius: '5px',
        marginBottom: '25px',
    },
})

const ExpandButton = ({ type, expanded, handleExpand, children }) => {
    const classes = useStyles({ expanded })

    const renderIconOrChildren = () => {
        if (children) return children
        else if (type === 'Violations') {
            return (
                <Box className={classes.violationsIconContainer}>
                    <Icon name="AngledArrow" color="#d0021b" />
                </Box>
            )
        }

        return (
            <Icon
                name={type}
                color="#307fc1"
                style={{
                    marginBottom: '25px',
                }}
            />
        )
    }

    return (
        <IconButton onClick={handleExpand} className={classes.iconButton}>
            {renderIconOrChildren()}
        </IconButton>
    )
}

ExpandButton.propTypes = {
    type: PropTypes.oneOf(['Comments', 'Approvers', 'Violations']),
}

export default ExpandButton
