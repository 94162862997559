import React, { useEffect, useState } from 'react'
import { Avatar, WithCloseButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    Box,
    Collapse,
    styled,
    Typography,
    Radio,
    FormControlLabel,
    makeStyles,
    Divider as MuiDivider,
} from '@material-ui/core'
import PeopleSearch from 'components/Filters/PeopleSearch'
import { Card } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'
import { useCurrentPerson } from 'hooks'

const Divider = styled(MuiDivider)({
    margin: '10px 0px',
    width: '100%',
})

const ControlsContainer = styled(Box)({
    padding: '0px 20px 0px 20px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '325px',
})

const useFormControlStyles = makeStyles(() => ({
    formLabelMargin: {
        marginRight: '30px',
        padding: '10px',
    },

    radio: {
        padding: '0px 12px 0px 0px',
    },

    checked: {
        '&, & + $label': {
            color: '#000000',
        },
    },
    formControlLabel: {
        padding: '6px',
    },

    label: {
        color: '#767676',
    },
}))

const StyledRadio = ({ checked, label, onChange, value }) => {
    const classes = useFormControlStyles()
    return (
        <FormControlLabel
            classes={{
                root: classes.formControlLabel,
                label: classes.label,
            }}
            checked={checked}
            value={value}
            label={label}
            labelPlacement="end"
            control={
                <Radio
                    icon={<Icon name="Radio" />}
                    classes={{
                        root: classes.radio,
                        checked: classes.checked,
                    }}
                    checkedIcon={<Icon name="RadioFilled" />}
                />
            }
            onChange={onChange}
        />
    )
}

const ApproverPersonFilterSomeoneElseIndicator = styled(WithCloseButton)({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
})

const ApproverTypeControl = ({ approverType, approver, handleChange }) => {
    const { t } = useTranslation()

    const { data: currentPerson } = useCurrentPerson()

    return (
        <ControlsContainer>
            <StyledRadio
                label={t('MyTasks_Current')}
                checked={approverType === 'current'}
                onChange={() => handleChange('current', approver)}
            />
            <StyledRadio
                label={t('MyTasks_Past')}
                checked={approverType === 'past'}
                onChange={() => handleChange('past', approver ?? currentPerson)}
            />
            <StyledRadio
                label={t('MyTasks_NoApprover')}
                checked={approverType === 'noApprover'}
                onChange={() => handleChange('noApprover', null)}
            />
            {approverType !== 'noApprover' && <Divider variant="middle" />}
        </ControlsContainer>
    )
}

const ApproverPersonFilter = ({
    title,
    approverType,
    approver,
    handleChange,
    ...rest
}) => {
    const { data: currentPerson } = useCurrentPerson()
    const { t } = useTranslation()

    const [selectedValue, setSelectedValue] = useState('anybody')

    useEffect(() => {
        switch (approverType) {
            case 'current':
                if (!approver) {
                    setSelectedValue('anybody')
                } else if (approver.id === currentPerson.id) {
                    setSelectedValue('mySelf')
                } else {
                    setSelectedValue('someOneElse')
                }
                break
            case 'past':
                if (approver && approver.id === currentPerson.id) {
                    setSelectedValue('mySelf')
                } else {
                    setSelectedValue('someOneElse')
                }
                break
            case 'noApprover':
            default:
                setSelectedValue('anybody')
                break
        }
    }, [approverType, approver])

    const onHandleChange = (event) => {
        if (event.target.value === 'mySelf') {
            setSelectedValue(event.target.value)
            handleChange(approverType, currentPerson)
        } else {
            setSelectedValue(event.target.value)
            handleChange(approverType, null)
        }
    }

    const content = () => {
        return (
            <>
                <ApproverTypeControl
                    approverType={approverType}
                    approver={approver}
                    handleChange={handleChange}
                />
                {approverType !== 'noApprover' && (
                    <>
                        {selectedValue === 'someOneElse' && approver ? (
                            <>
                                <Box padding="0px 20px 0px 20px">
                                    <ApproverPersonFilterSomeoneElseIndicator
                                        onClose={() => {
                                            setSelectedValue('anybody')
                                            handleChange(approverType, null)
                                        }}
                                    >
                                        <Avatar
                                            src={getFullImageUrl(
                                                approver.imageThumbUrl,
                                            )}
                                        />
                                        <Typography
                                            style={{
                                                marginLeft: '21px',
                                                wordBreak: 'break-all',
                                            }}
                                            variant="body1"
                                        >
                                            {approver.friendlyName}
                                        </Typography>
                                    </ApproverPersonFilterSomeoneElseIndicator>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box
                                    padding="0px 20px 0px 20px"
                                    display="flex"
                                    alignItems="center"
                                    flexWrap="wrap"
                                    maxWidth="325px"
                                >
                                    {approverType === 'current' && (
                                        <StyledRadio
                                            label={t('MyTasks_Anybody')}
                                            checked={
                                                selectedValue === 'anybody'
                                            }
                                            value="anybody"
                                            onChange={onHandleChange}
                                        />
                                    )}
                                    <StyledRadio
                                        label={t('MyTasks_Myself')}
                                        checked={selectedValue === 'mySelf'}
                                        value="mySelf"
                                        onChange={onHandleChange}
                                    />
                                    <StyledRadio
                                        label={t('MyTasks_SomeoneElse')}
                                        checked={
                                            selectedValue === 'someOneElse'
                                        }
                                        value="someOneElse"
                                        onChange={onHandleChange}
                                    />
                                </Box>
                                <Collapse in={selectedValue === 'someOneElse'}>
                                    <Box padding="20px">
                                        <PeopleSearch
                                            label={t('MyTasks_SomeoneElse')}
                                            onChange={(_, value) =>
                                                handleChange(
                                                    approverType,
                                                    value,
                                                )
                                            }
                                            {...rest}
                                        />
                                    </Box>
                                </Collapse>
                            </>
                        )}
                    </>
                )}
            </>
        )
    }

    return (
        <Card
            cardTitle={title}
            color="#fff"
            collapsible
            expanded={approver !== null}
        >
            {content()}
        </Card>
    )
}

export default ApproverPersonFilter
