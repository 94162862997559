import config from 'config'
import { isNilOrEmpty } from 'packages/core'

const statusColors = {
    approved: '#1db57a',
    rejected: '#d0021b',
    open: '#d66a00',
}

export const getStatusColor = (status: any, defaultColor = '#ffffff') => {
    return Object.keys(statusColors).includes(status)
        ? //@ts-ignore
          statusColors[status]
        : defaultColor
}

export const getFullImageUrl = (imagePath: any) =>
    !isNilOrEmpty(imagePath) ? `${config.BASE_EID_URL}${imagePath}` : undefined

export const mobileScreenWidth = '767px'
export const tabletScreenWidth = '768px'
export const smallScreenWidth = '1022px'

export const COMMENT_MAX_LENGTH = 1000
export const APPROVAL_COMMENT_MAX_LENGTH = 500

export const isWholeNumber = (number: any) => {
    const result = number - Math.floor(number) !== 0

    if (result) return false
    else return true
}

export const roundOffNumber: (number: any, upto: any) => number = (
    number,
    upto,
) => {
    if (isWholeNumber(number)) return parseInt(number)

    const pow = Math.pow(10, upto)
    return Math.round(number * pow) / pow
}
