import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, withStyles, InputBase, styled } from '@material-ui/core'
import moment from 'moment'
import useSubcomponents from 'useSubcomponents'
import { DateTimePicker } from '@material-ui/pickers'

const TextButtonContainer = styled(Box)({
    color: '#307fc1',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 10,
    minWidth: '110px',
    paddingLeft: '16px',
    '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    margin: '0px 8px',
})

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        maxWidth: "250px",

        borderBottom: '2px solid #9B9B9B'
    },
    input: {
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)


const styles = {
    fulfillmentScheduleDateControl: {
        fontSize: '12px',
        lineHeight: 1.33,
        color: '#9b9b9b',
        textTransform: 'uppercase',
        minWidth: '204px',
        display: 'block',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        fontWeight: 'bold',

    },
}

export const FulfillmentScheduleDateControl =
    ({ item,
        withOutLabel,
        onEdit,
    }) => {

        const { t } = useTranslation()

        const {
            hasAccessToFulfillmentScheduleDateControl,
            hasAccessToEditFulfillmentScheduleDateControl
        } = useSubcomponents()

        const [selectedFulfillmentScheduleDate, setSelectedFulfillmentScheduleDate] =
            useState(item?.fulFillmentScheduleDateUtc ?
                moment.utc(item.fulFillmentScheduleDateUtc) :
                null)

        const [checked, setChecked] = useState(false)

        const fulfillmentScheduleDateControlReadOnly =
            <Box
                marginY="7.5px"
                display="flex"
                alignItems="center"
            >
                {selectedFulfillmentScheduleDate
                    ? selectedFulfillmentScheduleDate.clone().local()
                        .format('LLL')
                    : null}

            </Box>

        const isReadOnly = hasAccessToFulfillmentScheduleDateControl && item.fulFillmentScheduleDateUtc
        const isEditableFulfillmentScheduleDate =
            (hasAccessToFulfillmentScheduleDateControl &&
                hasAccessToEditFulfillmentScheduleDateControl) &&
            item.fulFillmentScheduleDateUtc &&
            item.isFulFillmentScheduleDateEditable

        const handleSave = () => {
            setChecked(prev => !prev)
            onEdit({
                fulFillmentScheduleDateUtc:
                    selectedFulfillmentScheduleDate
            })
        }


        const fulfillmentScheduleDateControlEditable =
            <Box display='flex' alignItems='center'>
                {checked
                    ?
                    <DateTimePicker
                        disablePast
                        onChange={(date) => {
                            const dateToSet = date
                                .clone()
                                .utc()
                            setSelectedFulfillmentScheduleDate(dateToSet)
                        }}
                        TextFieldComponent={(props) =>
                            <StyledInput value={props.value} onClick={props.onClick} />
                        }
                        value={selectedFulfillmentScheduleDate
                            ? selectedFulfillmentScheduleDate.clone().local()
                            : null}
                        okLabel={t('MyTasks_Ok')}
                        cancelLabel={t('MyTasks_Cancel')}

                    />
                    :
                    fulfillmentScheduleDateControlReadOnly
                }

                {!checked
                    ? <TextButtonContainer onClick={() => setChecked(prev => !prev)}>
                        {t('MyTasks_Edit')}
                    </TextButtonContainer>
                    :
                    <TextButtonContainer onClick={handleSave}
                    >
                        {t('MyTasks_Save')}
                    </TextButtonContainer>
                }

            </Box>

        return <>
            {!withOutLabel && <Box
                marginY="7.5px"
                alignItems="center"
                style={styles.fulfillmentScheduleDateControl}
            >
                {(isReadOnly || isEditableFulfillmentScheduleDate) && t(
                    'MyTasks_FulfillmentScheduleDate',
                )}
            </Box>}

            <Box>

                {(isReadOnly && !isEditableFulfillmentScheduleDate) && fulfillmentScheduleDateControlReadOnly}
                {isEditableFulfillmentScheduleDate && fulfillmentScheduleDateControlEditable}

            </Box>
        </>
    }

