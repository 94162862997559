import React, { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { DecisionConfirmationButton } from 'components'
import {
    useRequestItemApprovalDecisions,
    useSubmitRequestItemDecision,
} from 'hooks'
import { APPROVAL_COMMENT_MAX_LENGTH, mobileScreenWidth } from 'utils'
import { ViolatingItemDecision } from './ViolatingItemDecision'

const DecisionButtonsContainer = styled(Box)({
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 30px',

    [`@media (max-width:${mobileScreenWidth})`]: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& >span': {
            marginBottom: '12px',
        },
    },
})

export const ItemApprovalDecisionPrompt = ({ requestItem, refetch }) => {
    const {
        data: approvalDecisions,
        isLoading: isLoadingApprovalDecisions,
        refetch: refetchDecisions,
    } = useRequestItemApprovalDecisions(
        requestItem.id,
        requestItem.isApprovable,
    )

    const [decisionComment, setDecisionComment] = useState('')

    const [
        submitRequestItemDecision,
        { isLoading: isSubmittingRequestItemDecision },
    ] = useSubmitRequestItemDecision(
        requestItem.businessRequestId,
        requestItem.id,
    )

    return (
        <DecisionButtonsContainer>
            {isLoadingApprovalDecisions ? (
                <Box marginX="30px">
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width={192}
                        height={40}
                    />
                </Box>
            ) : requestItem.isViolatingItem ? (
                <ViolatingItemDecision
                    item={requestItem}
                    decisions={approvalDecisions}
                />
            ) : (
                approvalDecisions.map((ad) => (
                    <Box component="span" key={ad.decisionId} marginX="12px">
                        <DecisionConfirmationButton
                            approvalDecision={ad}
                            decisionComment={decisionComment}
                            maxCharacters={APPROVAL_COMMENT_MAX_LENGTH}
                            setDecisionComment={setDecisionComment}
                            isLoading={isSubmittingRequestItemDecision}
                            onSuccess={() => {
                                const reqData = {
                                    id: requestItem.id,
                                    stepId: requestItem.currentApprovalStepId,
                                    decisionId: ad.decisionId,
                                }
                                if (decisionComment) {
                                    reqData.comment = decisionComment
                                }

                                return submitRequestItemDecision([
                                    reqData,
                                ]).then(() => {
                                    refetch()
                                    refetchDecisions()
                                })
                            }}
                        />
                    </Box>
                ))
            )}
        </DecisionButtonsContainer>
    )
}
