import { CSSProperties, FC, Fragment, memo } from 'react'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { getStatusColor } from './utils'
import IconContainer from './Icons/IconContainer'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { IItemWithApprovalSteps } from 'types'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginBottom: '12px',
            marginTop: '12px',
            paddingTop: '8px',
            paddingBottom: '8px',
            position: 'relative',
            '& *': {
                zIndex: 2,
            },
        },
    }),
)

const getStyles: (status: any) => CSSProperties = (status: any) => ({
    fontWeight: 'normal',
    color:
        status === 'Completed' ||
        status === 'Approved' ||
        status === 'Rejected' ||
        status === 'Canceled' ||
        status === 'Error'
            ? getStatusColor(status)
            : '#aab0b4',
    fontSize: '12px',
})

const getRequestItemStatusAndStep = (item: IItemWithApprovalSteps) => {
    const firstPendingStep = item.approvalSteps.find(
        (i: any) => i.status === 'Open',
    )

    let status
    let steps = item.approvalSteps
    if (
        item.status === 'Completed' ||
        item.status === 'Approved' ||
        item.status === 'Rejected' ||
        item.status === 'Canceled' ||
        item.status === 'Error'
    ) {
        status = item.status
    } else if (firstPendingStep) {
        status = 'WaitingOn'
    } else if (steps.length > 0) {
        status = steps[0].status
    } else {
        status = item.status
    }

    const stepFriendlyName = firstPendingStep
        ? firstPendingStep.friendlyName
        : null

    return {
        status,
        stepFriendlyName,
    }
}

export type RequestItemNodeProps = {
    id: string
    item: IItemWithApprovalSteps
}

const leftMarginsMap = {
    0: '0',
    1: '56px',
    2: '112px',
}

const RequestItemNode: FC<RequestItemNodeProps> = memo((props) => {
    const { id, children, item } = props

    const { t } = useTranslation()

    const itemStatusAndStep = getRequestItemStatusAndStep(item)

    const statusToUse = item.preApproved ? 'Approved' : itemStatusAndStep.status

    const classes = useStyles()

    return (
        <Fragment>
            <Box
                className={classes.root}
                marginLeft={leftMarginsMap[item.indentationLevel]}
            >
                <Box display="flex" alignItems="center">
                    <IconContainer id={id} style={styles.iconContainer}>
                        <Icon name={item.resourceType} style={styles.icon} />
                    </IconContainer>

                    <Box paddingX="20px">
                        <Attribute
                            isText={!item.itemTypeActionFriendlyName}
                            label={
                                item.itemTypeActionFriendlyName ? (
                                    <Box>
                                        <Box
                                            color={
                                                statusToUse === 'Completed' ||
                                                statusToUse === 'Approved' ||
                                                statusToUse === 'Rejected' ||
                                                statusToUse === 'Canceled' ||
                                                statusToUse === 'Error'
                                                    ? getStatusColor(
                                                          itemStatusAndStep.status,
                                                      )
                                                    : 'initial'
                                            }
                                            fontWeight={'bold'}
                                        >
                                            {item.resourceRequested}
                                        </Box>
                                        {item.itemTypeActionFriendlyName && (
                                            <Box
                                                style={getStyles(statusToUse)}
                                                fontWeight={'bold'}
                                            >
                                                {
                                                    item.itemTypeActionFriendlyName
                                                }
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    item.resourceRequested
                                )
                            }
                            status={statusToUse}
                        />
                        {item.globalApprovalSkipped && (
                            <Attribute
                                isText={false}
                                label={
                                    <Box color="rgb(1, 174, 143)">
                                        {t('MyTasks_GlobalApprovalSkipped')}
                                    </Box>
                                }
                            />
                        )}
                        <Attribute
                            isText={true}
                            label={
                                item.preApproved
                                    ? t('MyTasks_PreApproved')
                                    : statusToUse === 'NotReady'
                                    ? undefined
                                    : t(`MyTasks_${statusToUse}`)
                            }
                            value={
                                statusToUse === 'Completed' ||
                                statusToUse === 'Approved' ||
                                statusToUse === 'Rejected' ||
                                statusToUse === 'Canceled' ||
                                statusToUse === 'Error'
                                    ? null
                                    : itemStatusAndStep.stepFriendlyName
                            }
                            fontSize={'12px'}
                            status={statusToUse}
                        />
                    </Box>
                </Box>
            </Box>
            {children}
        </Fragment>
    )
})

const styles = {
    iconContainer: {
        backgroundImage: 'linear-gradient(135deg, #afbcc6, #668397)',
    },
    icon: {
        color: '#ffffff',
        width: '20px',
        height: '20px',
    },
}

const Attribute: FC<any> = memo((props) => {
    const { label, value, isText, status, bold, fontSize } = props
    return (
        <Box display="flex" alignItems="center">
            {isText ? (
                <Typography
                    style={{
                        color: getStatusColor(status),
                        fontWeight: bold ? 'bold' : 'normal',
                        fontSize: fontSize ? fontSize : '14px',
                    }}
                >
                    {label}
                    {value && ':'}
                </Typography>
            ) : (
                label
            )}
            <Typography
                style={{
                    color: getStatusColor(status),
                    fontWeight: bold ? 'bold' : 'normal',
                    paddingLeft: '4px',
                    fontSize: fontSize ? fontSize : '14px',
                }}
            >
                {value}
            </Typography>
        </Box>
    )
})

export { RequestItemNode }
