import React from 'react'
import ImageSrc from './image.png'
import { Typography, styled } from '@material-ui/core'

const Container = styled('div')({
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '5rem 0rem',
    overflow: 'auto',
})

const TextContainer = styled('div')({
    margin: 'auto',
    textAlign: 'center',
    marginBottom: '4rem',
})

const HeadingText = styled(Typography)({
    color: '#3f3767',
    lineHeight: 1,
    fontSize: '4.5rem',
    marginBottom: '32px',
    '@media (max-width:960px)': {
        fontSize: '2.5rem',
    },
})

const StyledImg = styled('img')({
    margin: 'auto',
    height: '50vh',
    '@media (max-width:960px)': {
        height: '30vh !important',
    },
})

const MaintenancePage = ({ title, description }) => {
    return (
        <Container>
            <TextContainer>
                <HeadingText variant="h1">{title}</HeadingText>
                <Typography
                    style={{
                        color: '#000000',
                        fontSize: '1.5rem',
                        lineHeight: 1.33,
                    }}
                >
                    {description}
                </Typography>
            </TextContainer>

            <StyledImg src={ImageSrc} alt={title}></StyledImg>
        </Container>
    )
}

// MaintenancePage.defaultProps = {
//     title: <Trans i18nKey="Common_UnderMaintenanceTitle" />,
//     description: <Trans i18nKey="Common_UnderMaintenanceDescription" />,
// }

MaintenancePage.defaultProps = {
    title: 'Under Maintenance',
    description: 'Making things better, please stand by.',
}

export default MaintenancePage
