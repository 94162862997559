import React from 'react'
import { Link, Box, makeStyles, Typography } from '@material-ui/core'
import ForbiddenImageSrc from './forbidden.png'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: ' #eef0f4',
        textAlign: 'center',
        '& img': {
            marginTop: '6vw',
            margin: 'auto',
            maxWidth: '50%',
        },
    },
    content: {
        [theme.breakpoints.up('md')]: {
            marginTop: '-15%',
        },
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'wrap',
        textAlign: 'center',
        background: 'transparent',
        left: 0,
        right: 0,
    },
    footer: {
        maxWidth: 625,
        marginTop: '-45px',
        margin: 'auto',
        left: 0,
        right: 0,
        marginBottom: '70px',
    },
    container: {
        marginTop: '-65px',
        [theme.breakpoints.down('lg')]: {},
    },
    forbiddenText: {
        color: ' #3f3767',
        fontWeight: '300',
        fontSize: '14vw',
    },
    text: {
        fontSize: '30px',
        [theme.breakpoints.down('md')]: {
            fontSize: '22px',
            paddingLeft: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.25vw',
            paddingLeft: '32px',
        },
    },
}))

const ForbiddenPage = () => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.root}>
                <img src={ForbiddenImageSrc} alt="Forbidden Page" />

                <Box className={classes.content}>
                    <Box className={classes.container}>
                        <Typography className={classes.forbiddenText}>
                            403
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.footer}>
                    <Box
                    // marginTop="6vh"
                    >
                        <Box>
                            <Typography style={{ fontSize: '22px' }}>
                                You do not have permission to access the
                                requested page. I'm sorry buddy… I'm driving you
                                to the
                                <Link
                                    component="a"
                                    underline="none"
                                    href="/"
                                    style={{
                                        color: '#453fbb',
                                        fontSize: '22px',
                                        fontWeight: 300,
                                        marginLeft: '8px',
                                    }}
                                >
                                    Home Page
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default ForbiddenPage
